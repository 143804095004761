import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "grid grid-cols-12 gap-1" }
const _hoisted_3 = { class: "col-span-12 lg:col-span-7 print:col-span-8 print:col-start-3" }
const _hoisted_4 = { class: "col-span-12 lg:col-span-5 print:col-span-7" }
const _hoisted_5 = { class: "col-span-12 print:col-span-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeader = _resolveComponent("BaseHeader")!
  const _component_Main4DView = _resolveComponent("Main4DView")!
  const _component_VentilationDistribution = _resolveComponent("VentilationDistribution")!
  const _component_MetricsList = _resolveComponent("MetricsList")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.disclaimer)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "marked-font inline max-[596px]:text-[.65rem]",
          innerHTML: _ctx.marked.parse(_ctx.disclaimer)
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createVNode(_component_BaseHeader, { title: _ctx.rsLabel }, null, 8, ["title"])
    ]),
    (_ctx.getScan)
      ? (_openBlock(), _createBlock(_component_BaseCard, { key: 1 }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_Main4DView, {
                  showInformationModal: _ctx.showInformationModal,
                  showTitle: !_ctx.previewReport
                }, null, 8, ["showInformationModal", "showTitle"])
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_VentilationDistribution, {
                  VDP: _ctx.getScan.histogram.vdp,
                  "data-cy": "ventilation-distribution",
                  showInformationModal: _ctx.showInformationModal
                }, null, 8, ["VDP", "showInformationModal"]),
                (_ctx.showMetricsComponent)
                  ? (_openBlock(), _createBlock(_component_MetricsList, {
                      key: 0,
                      class: "hidden lg:grid mt-2",
                      showInformationModal: _ctx.showInformationModal,
                      metricsType: _ctx.TabTypes.REPORT_SUMMARY
                    }, null, 8, ["showInformationModal", "metricsType"]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_5, [
                (_ctx.showMetricsComponent)
                  ? (_openBlock(), _createBlock(_component_MetricsList, {
                      key: 0,
                      class: "grid lg:hidden",
                      showInformationModal: _ctx.showInformationModal,
                      metricsType: _ctx.TabTypes.REPORT_SUMMARY
                    }, null, 8, ["showInformationModal", "metricsType"]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}