export enum Information {
  REGIONAL_VENTILATION_VISUALIZATION = 'regional-ventilation-visualization.md',
  RVV_SLICES = 'rvv-slices.md',
  TIDAL_VOLUME = 'tidal-volume.md',
  VENTILATION_GRAPH_HISTOGRAM = 'ventilation-graph-histogram.md',
  VENTILATION_HETEROGENEITY = 'ventilation-heterogeneity.md',
  VIDEO_FRAMES = 'video-frames.md',
  GENERAL_DISCLAIMER = 'general-disclaimer.md',
  CHART_DISCLAIMER = 'chart-disclaimer.md',
  SPECIFIC_VENTILATION_HISTOGRAM = 'specific-ventilation-histogram.md',
  SPECIFIC_VENTILATION_LVR = 'specific-ventilation-lvr.md',
  SPECIFIC_VENTILATION_MSV = 'specific-ventilation-msv.md',
  SPATIO_TEMPORAL_HETEROGENEITY_GRAPH = 'spatio-temporal-heterogeneity-graph.md',
  SPATIO_TEMPORAL_HETEROGENEITY_SCORE = 'spatio-temporal-heterogeneity-score.md',
}
