export enum CoronalSlices {
  BACK = 'Back',
  MIDDLE = 'Middle',
  FRONT = 'Front',
}

export enum AxialSlices {
  UPPER = 'Upper',
  CENTRE = 'Centre',
  LOWER = 'Lower',
}
