import { FLOW_CHART_CONSTANTS } from '@/constants/FlowChartConstants';
import { MetricsDetailService } from './MetricsDetailService';
import { Information } from '@/constants/Information';
import { useLabelsStore } from '@/stores/labels';
import { useScanStore } from '@/stores/scan';
import { useI18n } from 'vue-i18n';
import { MetricsDetails } from '@/types/Metrics';

export class FlowOscillatoryMetricsDetailsService implements MetricsDetailService {
  gridClass = '';
  metricsDataList: MetricsDetails[] = [];
  labelsStore = useLabelsStore();
  scanStore = useScanStore();
  translate = useI18n();

  getMetricsData() {
    this.metricsDataList.push(this.getFlowOscillatory());
    this.gridClass = '';
  }

  getFlowOscillatory(): MetricsDetails {
    const score = this.scanStore?.getScan?.flowOscillatoryMetrics?.score ?? 0;
    let formattedScore = score.toFixed(1);

    if (score > 8.0) {
      formattedScore = '>8.0';
    } else if (score < 0.0) {
      formattedScore = '<0.0';
    } else {
      formattedScore = FLOW_CHART_CONSTANTS.SIGN + score.toFixed(1);
    }

    return {
      headerTitle: this.labelsStore?.getLabels?.flowOscillatory?.fos || '',
      informationModalText: Information.SPATIO_TEMPORAL_HETEROGENEITY_SCORE,
      barDetails: [
        {
          value: formattedScore,
          class: 'w-full',
          valueClass: 'border-teal border-red',
        },
      ],
    };
  }
}
