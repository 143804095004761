import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col w-full h-full" }
const _hoisted_2 = { class: "flex flex-row flex-grow h-full" }
const _hoisted_3 = { class: "flex flex-col justify-between h-full w-52 3xl:w-72 bg-blue-darker 4xl:w-3/12" }
const _hoisted_4 = { class: "flex flex-col overflow-y-auto scrollbar-hide" }
const _hoisted_5 = { class: "w-full py-2 pr-2 bg-blue-darker" }
const _hoisted_6 = { class: "flex flex-col w-full h-full bg-grey-lightest" }
const _hoisted_7 = { class: "h-full p-3 3xl:p-5 4xl:p-12 overflow-y-scroll" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseImg = _resolveComponent("BaseImg")!
  const _component_NavigationBar = _resolveComponent("NavigationBar")!
  const _component_FooterTab = _resolveComponent("FooterTab")!
  const _component_ScanDetails = _resolveComponent("ScanDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BaseImg, {
              imageUrl: _ctx.informationStoreLogo,
              disableSpinner: true
            }, null, 8, ["imageUrl"])
          ]),
          _createVNode(_component_NavigationBar)
        ]),
        _createVNode(_component_FooterTab)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_ScanDetails),
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ])
  ]))
}