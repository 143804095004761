
import ColorbarImg from '@/assets/images/color_20px.png';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    colorBar: {
      type: Boolean,
      default: true,
    },
    xmin: {
      type: Number,
      default: 0,
      required: true,
    },
    xmax: {
      type: Number,
      default: 0,
      required: true,
    },
    steps: {
      type: Number,
      default: 4,
    },
    colorbarImg: {
      type: String,
      default: ColorbarImg,
    },
  },
  setup(props) {
    const filteredValues = computed(() => {
      let step = (props.xmax - props.xmin) / props.steps;
      return Array.from({ length: 5 }, (v, i) => props.xmin + i * step);
    });
    return {
      filteredValues,
    };
  },
});
