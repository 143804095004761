
import VentilationReportLogo from '@/assets/images/ventilation-report-logo.png';

import NavigationBar from '@/components/NavigationBar.vue';
import FooterTab from '@/components/FooterTab.vue';
import ScanDetails from '@/components/ScanDetails.vue';
import { computed, defineComponent, onMounted } from 'vue';
import { useInformationStore } from '@/stores/information';
import { useScanStore } from '@/stores/scan';

export default defineComponent({
  components: {
    NavigationBar,
    FooterTab,
    ScanDetails,
  },
  setup() {
    const ventilationReportLogo = VentilationReportLogo;
    const informationStoreLogo = computed(() => useInformationStore().getReportGuideImages.logo);
    const informationStore = useInformationStore();
    const scanStore = useScanStore();

    const getScan = computed(() => scanStore.getScan);

    onMounted(async () => {
      let firstLoading = true;
      Object.values(informationStore.reportGuideImages).filter((val) => val != '').length > 0
        ? (firstLoading = false)
        : (firstLoading = true);
      if (getScan.value && firstLoading) {
        const resp = await informationStore.getProductLogoImageRequest(getScan.value?.resultId, getScan.value!.product);
      }
    });

    return { ventilationReportLogo, informationStoreLogo };
  },
});
