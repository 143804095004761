
import MetricsList from '@/components/ui/MetricsList.vue';
import { defineComponent, onMounted, computed, ref } from 'vue';
import VentilationDistribution from '../components/reports/VentilationDistributionChart.vue';
import Main4DView from '../components/reportSummary/Main4DView.vue';
import { useScanStore } from '@/stores/scan';
import { useLabelsStore } from '@/stores/labels';
import { useNotification } from '@/composables/notification';
import { useContactStore } from '@/stores/contact';
import { marked } from 'marked';
import { useRouter } from 'vue-router';
import { TabTypes } from '@/constants/TabTypes';

export default defineComponent({
  components: {
    VentilationDistribution,
    Main4DView,
    MetricsList,
  },
  props: {
    showInformationModal: {
      type: Boolean,
      required: false,
      value: true,
      default: true,
    },
    previewReport: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const scanStore = useScanStore();
    const getScan = computed(() => scanStore.getScan);
    const labelsStore = useLabelsStore();
    const contactStore = useContactStore();
    const disclaimerKey = 'preamble.md';
    const disclaimer = computed(() => contactStore.getDisclaimer(disclaimerKey));
    const getLabels = computed(() => labelsStore.getLabels);
    const router = useRouter();
    const rsLabel = computed(() => labelsStore.getLabels?.rs);
    const showMetricsComponent = ref(false);

    const notification = useNotification();

    onMounted(async () => {
      if (!getLabels.value && getScan.value) {
        const resp = await labelsStore.fetchLabels(getScan.value.resultId);

        if (!resp.success) {
          notification.showError(resp.errorMsg);
        }
      }

      if (getScan.value) {
        if (props.previewReport && !disclaimer.value) {
          const resp = await contactStore.fetchDisclaimer(disclaimerKey, getScan.value.resultId);

          if (!resp.success) {
            router.push('/error/500');
          }
        }
      }
      showMetricsComponent.value = true;
    });

    return {
      getScan,
      marked,
      disclaimer,
      rsLabel,
      showMetricsComponent,
      TabTypes,
    };
  },
});
