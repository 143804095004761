import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-606d45b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative min-w-full min-h-full" }
const _hoisted_2 = {
  key: 0,
  class: "indicators left-1"
}
const _hoisted_3 = {
  key: 1,
  class: "indicators right-1"
}
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseImg = _resolveComponent("BaseImg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.previewReport)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, "R"))
      : _createCommentVNode("", true),
    (!_ctx.previewReport)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, "L"))
      : _createCommentVNode("", true),
    (_ctx.showGuides)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "guides",
            style: _normalizeStyle(_ctx.getUpperTop)
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('positions.upper')), 1)
          ], 4),
          _createElementVNode("div", {
            class: "guides",
            style: _normalizeStyle(_ctx.getCentreTop)
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('positions.middle')), 1)
          ], 4),
          _createElementVNode("div", {
            class: "guides",
            style: _normalizeStyle(_ctx.getLowerTop)
          }, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('positions.lower')), 1)
          ], 4)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showGuides)
      ? (_openBlock(), _createBlock(_component_BaseImg, {
          key: 3,
          imageUrl: _ctx.imageUrl,
          alt: _ctx.alt,
          emitHeight: true,
          onImageHeight: _ctx.imageHeightUpdate,
          spinnerLoading: true
        }, null, 8, ["imageUrl", "alt", "onImageHeight"]))
      : (_openBlock(), _createBlock(_component_BaseImg, {
          key: 4,
          imageUrl: _ctx.imageUrl,
          alt: _ctx.alt
        }, null, 8, ["imageUrl", "alt"]))
  ]))
}