import { defineStore } from 'pinia';
import axios from 'axios';

import { getEndpoint } from '@/apiConnector/endpoints';
import { Labels } from '@/types/Labels';

import { handleEndpointErrors, handleEndpointSuccess } from '@/utility/helpers';

interface State {
  labels: Labels | null;
}

export const useLabelsStore = defineStore('labels', {
  state: (): State => ({
    labels: null,
  }),
  getters: {
    getLabels: (state) => state.labels,
  },
  actions: {
    async fetchLabels(scanId: string) {
      try {
        const url = getEndpoint('labels', scanId);
        const resp = await axios.get(url);
        const data = resp.data as Labels;

        this.labels = data;

        return handleEndpointSuccess(data);
      } catch (e: unknown) {
        return handleEndpointErrors(e, true);
      }
    },
  },
});
