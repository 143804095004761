import { defineStore } from 'pinia';
import axios from 'axios';

import { getEndpoint } from '@/apiConnector/endpoints';
import { Scan } from '@/types/Scan';

import { handleEndpointErrors, handleEndpointSuccess } from '@/utility/helpers';
import { parseDateToRequiredFormatForReport } from '@/utility/dateutil';
interface State {
  scan: Scan | null;
}

export const useScanStore = defineStore('scan', {
  state: (): State => ({
    scan: null,
  }),
  getters: {
    getScan: (state) => state.scan,
  },
  actions: {
    async fetchScan(scanId: string) {
      try {
        const url = getEndpoint('scan', scanId);
        const resp = await axios.get(url);
        const data = resp.data.data as Scan;
        this.scan = data;
        this.scan.resultId = scanId;

        if (this.scan.dateAcquired) {
          this.scan.dateAcquired = parseDateToRequiredFormatForReport(this.scan.dateAcquired, false);
        }
        if (this.scan.datePrepared) {
          this.scan.datePrepared = parseDateToRequiredFormatForReport(this.scan.datePrepared, true);
        }

        if (!resp.headers['x-api-secret-issue']) {
          this.scan.issueNumber = 0;
        } else {
          this.scan.issueNumber = resp.headers['x-api-secret-issue'];
        }

        return handleEndpointSuccess(resp);
      } catch (e: unknown) {
        return handleEndpointErrors(e);
      }
    },
  },
});
