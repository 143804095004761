import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-5" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 1 }
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GDialog = _resolveComponent("GDialog")!

  return (!_ctx.preview)
    ? (_openBlock(), _createBlock(_component_GDialog, {
        key: 0,
        "max-width": "60%",
        "min-height": "30%",
        "data-cy": "dialog"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", {
                class: "mb-5 font-bold text-[2.5vh]",
                innerHTML: _ctx.marked.parse(_ctx.title)
              }, null, 8, _hoisted_2),
              _createElementVNode("div", {
                class: _normalizeClass(_ctx.imageGuide ? 'flex flex-row' : '')
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["prose text-[1.75vh]", _ctx.imageGuide ? 'basis-11/12' : 'inline']),
                  innerHTML: _ctx.marked.parse(_ctx.updatedContent)
                }, null, 10, _hoisted_3),
                (_ctx.imageGuide)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "w-1/4 basis-2/6 h-1/4",
                      src: _ctx.imageGuide
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ], 2)
            ])
          ])
        ]),
        _: 1
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "prose text-reportCustom",
          style: {"display":"contents","line-height":"1.25"},
          innerHTML: _ctx.marked.parse(_ctx.updatedContent)
        }, null, 8, _hoisted_6)
      ]))
}