<template>
  <div class="container" data-cy="empty-report-component">
    <BaseHeader :title="$t('reportPreview.header')" class="mb-4" />
    <BaseCard>
      <div class="grid grid-cols-1 gap-5 lg:grid-cols-2" data-cy="error-report-text">
        {{ $t(productText) }}
      </div>
    </BaseCard>
  </div>
</template>

<script>
import { marked } from 'marked';
import { computed, onMounted, defineComponent } from 'vue';
import { useContactStore } from '@/stores/contact';
import { useScanStore } from '@/stores/scan';
import { useNotification } from '@/composables/notification';

export default defineComponent({
  name: 'EmptyReport',
  props: {
    previewReport: {
      type: Boolean,
      value: false,
    },
  },
  setup() {
    const contactStore = useContactStore();
    const scanStore = useScanStore();
    const notification = useNotification();

    const scan = computed(() => scanStore.getScan);
    const productText = computed(() => scanStore.getScan.result.endUserMessage || '');
    const contact = computed(() => contactStore.getContact);

    onMounted(async () => {
      if (!contact.value) {
        const resp = await contactStore.fetchContact(scan.value.resultId);

        if (!resp.success) {
          notification.showError(resp.errorMsg + '');
          return;
        }
      }
    });

    return {
      marked,
      scan,
      productText,
    };
  },
});
</script>
