
import { computed, defineComponent, onMounted, ref } from 'vue';

import ColorBar from '../ColorbarComponent.vue';
import Vent4DZoomImg from './Vent4DZoomImg.vue';

import { Information } from '@/constants/Information';

import { useSliceStore } from '@/stores/slice';
import { useScanStore } from '@/stores/scan';

import { Views } from '@/constants/Views';
import { AxialSlices, CoronalSlices } from '@/constants/Slices';
import { SliceImageTypes } from '@/constants/SliceImageTypes';
import { useNotification } from '@/composables/notification';
import { getImageBlob } from '@/utility/helpers';
import { useLabelsStore } from '@/stores/labels';

export default defineComponent({
  components: {
    ColorBar,
    Vent4DZoomImg,
  },
  props: {
    showImages: {
      default: true,
    },
    showInformationModal: {
      type: Boolean,
      required: false,
    },
    showTitle: {
      default: true,
    },
  },
  setup() {
    const sliceStore = useSliceStore();
    const scanStore = useScanStore();
    const notification = useNotification();

    const getScan = computed(() => scanStore.getScan);
    const coronalUrl = ref('');
    const axialUrl = ref<string[]>([]);
    const labelsStore = useLabelsStore();
    const rvLabel = computed(() => labelsStore.getLabels?.rv);

    const coronalImageUrl = computed(() => {
      const s = sliceStore.filterSliceImages(Views.CORONAL, true, CoronalSlices.MIDDLE);
      if (!s.length) {
        return '';
      }
      const middleSlice = s[0];
      const staticMiddleSlice = middleSlice.files.find((f) => f.type === SliceImageTypes.STATIC);
      return staticMiddleSlice?.url;
    });
    // use that coronalImageUrl constant to call a file name
    const axialNamedImagesUrl = computed(() => {
      const namedSlices = sliceStore.filterSliceImages(Views.AXIAL, true);
      if (!namedSlices.length) {
        return null;
      }
      const staticMiddleSlice = namedSlices.map((s) => {
        const staticFile = s.files.find((f) => f.type === SliceImageTypes.STATIC);
        return staticFile?.url;
      });
      return staticMiddleSlice;
    });
    const axialGuides = computed(() => {
      const positions = {
        upper: 0,
        centre: 0,
        lower: 0,
      };
      const namedSlices = sliceStore.filterSliceImages(Views.AXIAL, true);
      if (namedSlices.length) {
        for (const s of namedSlices) {
          switch (s.position) {
            case AxialSlices.LOWER:
              positions.lower = s.pixelPosition;
              break;
            case AxialSlices.CENTRE:
              positions.centre = s.pixelPosition;
              break;
            case AxialSlices.UPPER:
              positions.upper = s.pixelPosition;
              break;
            default:
              break;
          }
        }
      }
      return positions;
    });

    onMounted(async () => {
      if (!coronalImageUrl.value?.startsWith('blob')) {
        getImageBlob('Coronal', coronalImageUrl.value);
      }
      // axial
      if (axialNamedImagesUrl.value) {
        for (const axialImage of axialNamedImagesUrl.value) {
          if (!axialImage?.startsWith('blob')) {
            // patch slices
            getImageBlob('Axial', axialImage);
          }
        }
      }
    });

    return {
      axialGuides,
      coronalImageUrl,
      axialNamedImagesUrl,
      Information,
      coronalUrl,
      axialUrl,
      sliceStore,
      rvLabel,
    };
  },
});
