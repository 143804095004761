
import InformationIcon from '@/assets/images/icons/information.svg';
import InformationModal from '@/components/InformationModal.vue';
import { computed, defineComponent, ref } from 'vue';

import { marked } from 'marked';

export default defineComponent({
  components: {
    InformationModal,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    showInformationModal: {
      type: Boolean,
      default: false,
    },
    informationModalText: {
      type: String,
      default: '',
    },
    imageGuide: {
      type: String,
      default: '',
    },
    markdownTitle: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const informationIcon = InformationIcon;
    const dialogState = ref(false);

    const changeDialogState = () => {
      dialogState.value = true;
    };

    const titleText = computed(() => {
      return props.markdownTitle ? marked.parse(props.title || '') : props.title;
    });
    return { informationIcon, dialogState, changeDialogState, marked, titleText };
  },
});
