
import { computed, defineComponent, WritableComputedRef } from 'vue';

export default defineComponent({
  props: {
    options: Array,
    modelValue: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const localValue: WritableComputedRef<string> = computed({
      get(): string {
        return props.modelValue;
      },
      set(newValue: string): void {
        emit('update:modelValue', newValue);
      },
    });

    return { localValue };
  },
});
