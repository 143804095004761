import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid gap-4 gird-cols-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeader = _resolveComponent("BaseHeader")!
  const _component_SliceDisplay = _resolveComponent("SliceDisplay")!
  const _component_BaseCard = _resolveComponent("BaseCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseHeader, {
      title: _ctx.axHeadLabel,
      showIcon: _ctx.showInformationModal,
      markdownTitle: _ctx.Information.RVV_SLICES
    }, null, 8, ["title", "showIcon", "markdownTitle"]),
    _createVNode(_component_BaseCard, null, {
      default: _withCtx(() => [
        _createVNode(_component_SliceDisplay, {
          sliceHeader: _ctx.rvAxLabel.replace('{spacing}', _ctx.spacing),
          slices: _ctx.axialSlices,
          "data-cy": "slice-display",
          showSlicedZoom: _ctx.showSlicedZoom,
          onSubHeader: _ctx.showInformationModal
        }, null, 8, ["sliceHeader", "slices", "showSlicedZoom", "onSubHeader"])
      ]),
      _: 1
    })
  ]))
}