const apiEndpointStart = '/api/viewer';

export const getEndpoint = (name: string, resultId: string) => {
  switch (name) {
    case 'file':
      return `${apiEndpointStart}/result/${resultId}/file`;
    case 'slice':
      return `${apiEndpointStart}/result/${resultId}/slice`;
    case 'content':
      return `${apiEndpointStart}/result/${resultId}/content`;
    case 'labels':
      return `${apiEndpointStart}/result/${resultId}/content/labels.json`;
    default:
      return `${apiEndpointStart}/result/${resultId}/scan`;
  }
};
