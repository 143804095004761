export const setItemLS = <T>(name: string, item: T) => {
  localStorage.setItem(name, JSON.stringify(item));
};

export const getItemLS = (name: string) => {
  const item = localStorage.getItem(name);

  try {
    if (item) {
      return JSON.parse(item);
    }

    return item;
  } catch (e) {
    return item;
  }
};

export const removeItemLS = (name: string) => {
  localStorage.removeItem(name);
};
