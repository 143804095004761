
import DownIcon from '@/assets/images/icons/down-turquoise.svg';
import UpIcon from '@/assets/images/icons/up-blue.svg';
import { computed, defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { ReportTypes } from '@/../src/constants/ReportTypes';
import { useScanStore } from '@/stores/scan';

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const scanStore = useScanStore();
    const getScan = computed(() => scanStore.getScan);

    let menu = computed(() => {
      let baseChild = [
        {
          title: t('navigationBar.reportSummary'),
          href: '/result-summary',
        },
        {
          title: t('navigationBar.axialImages'),
          href: '/axial-images',
        },
        {
          title: t('navigationBar.coronalImages'),
          href: '/coronal-images',
        },
        {
          title: t('navigationBar.ventilation4DVideo'),
          href: '/ventilation-video',
        },
      ];

      if (getScan.value?.specificVentilationHistogram) {
        baseChild.push({
          title: t('navigationBar.specificVentilation'),
          href: '/specific-ventilation',
        });
      }

      if (getScan.value?.flowOscillatoryMetrics) {
        baseChild.push({
          title: t('navigationBar.flowGraph'),
          href: '/flow-graph',
        });
      }

      let navigationBar = [
        {
          title: t('navigationBar.reportResults'),
          child: baseChild,
        },
        {
          title: t('navigationBar.contact4DMedical'),
          href: '/contact4d-medical',
        },
      ];

      if (getScan.value) {
        if (getScan.value.product === ReportTypes.EWD) {
          let index = navigationBar[0].child?.findIndex((item) => item.title === t('navigationBar.ventilation4DVideo'));
          if (index !== undefined && index !== -1) {
            navigationBar[0].child?.splice(index, 1);
          }
        }
      }

      return navigationBar;
    });

    const downIcon = DownIcon;
    const upIcon = UpIcon;

    return { menu, downIcon, upIcon };
  },
});
