import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import AppLayout from './layouts/AppLayout.vue';
import VueSidebarMenu from 'vue-sidebar-menu';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
import BaseUiPlugin from '@/plugins/baseui';

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import './assets/styling/main.css';
import '@fontsource/roboto';

import AxiosStorePlugin from '@/plugins/axios-store';

import router from './router';
import i18n from '@/plugins/i18n';

declare global {
  interface Window {
    VUE_APP_REST_URL: string;
    VUE_APP_I18N_LOCALE: string;
    VUE_APP_I18N_FALLBACK_LOCALE: string;
    VUE_APP_VERSION: string;
  }
}

const app = createApp(App);
const pinia = createPinia();

app.use(i18n);
app.use(router);
app.use(pinia);

app.use(Toast, {
  transition: 'Vue-Toastification__fade',
  maxToasts: 20,
  newestOnTop: true,
  toastClassName: 'toastNotification',
});

app.use(AxiosStorePlugin);
app.use(VueSidebarMenu);
app.use(BaseUiPlugin);

app.component('AppLayout', AppLayout);

app.mount('#app');
