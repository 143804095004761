
import { computed, defineComponent, ref, watchEffect, WritableComputedRef } from 'vue';
import tailwindConfig from '../../../tailwind.config.js';

export default defineComponent({
  props: {
    modelValue: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: false,
      default: 1,
    },
    max: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  setup(props, { emit }) {
    const localValue: WritableComputedRef<number> = computed({
      get(): number {
        return props.modelValue;
      },
      set(newValue: number): void {
        emit('update:modelValue', +newValue);
      },
    });

    const slider = ref<null | HTMLInputElement>(null);
    const valueBubble = ref<null | HTMLElement>(null);

    watchEffect(() => {
      if (!slider.value || !valueBubble.value) {
        return;
      }

      const val = localValue.value;
      const min = props.min;
      const max = props.max;
      const newVal = Number(((val - min) * 100) / (max - min));

      let minusVal = 0;

      if (screen.width >= Number(tailwindConfig.theme.extend.screens['4xl'].replace('px', ''))) {
        minusVal = 32.5 - (val / max) * 45;
      } else if (screen.width >= Number(tailwindConfig.theme.extend.screens['3xl'].replace('px', ''))) {
        minusVal = 16 - (val / max) * 15;
      } else {
        minusVal = 10 - (val / max) * 5;
      }

      valueBubble.value.style.left = `calc(${newVal}% + (${minusVal - newVal * 0.15}px))`;

      let percent = ((val - min) / (max - min)) * 100;
      slider.value.style.background = `linear-gradient(to right, ${tailwindConfig.theme.colors.turquoise.DEFAULT} 0%, ${tailwindConfig.theme.colors.turquoise.DEFAULT} ${percent}%, ${tailwindConfig.theme.colors.grey.light} ${percent}%, ${tailwindConfig.theme.colors.grey.light} 100%)`;
    });

    return { slider, valueBubble, localValue };
  },
});
