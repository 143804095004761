export default {
  "navigationBar": {
    "reportResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Results"])},
    "reportSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report Summary"])},
    "axialImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Axial Images"])},
    "coronalImages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coronal Images"])},
    "ventilation4DVideo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventilation 4D Video"])},
    "specificVentilation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific Ventilation"])},
    "flowGraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spatio-Temporal Heterogoeneity"])},
    "contact4DMedical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact 4D Medical"])}
  },
  "reportPreview": {
    "readReportViewer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How To Read Report Guide"])},
    "ventilationReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventilation Report - Executive Summary"])},
    "ventilationReportHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regional Ventilation Visualization"])},
    "ventilationReportHeaderSlices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regional Ventilation Visualization Slices"])},
    "readReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How To Read The Ventilation Report"])},
    "readReportEWD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How To Read The CT LVAS Ventilation Report"])},
    "reportTextEWD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your CT LVAS Report has not been completed, please contact 4DMedical support."])},
    "reportTextDAW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your XV LVAS Report has not been completed, please contact 4DMedical support."])},
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Report Generated"])}
  },
  "positions": {
    "upper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper"])},
    "middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle"])},
    "lower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower"])}
  },
  "footer": {
    "poweredBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Powered by"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright©4dmedical.com"])},
    "allRightsReserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All right reserved"])}
  },
  "mainHeader": {
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCAN ID"])},
    "dateAcquired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATE RECEIVED"])},
    "dateProcessed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATE PREPARED"])}
  },
  "colorbar": {
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less"])},
    "legend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normalised Specific Ventilation"])},
    "alt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Histogram Gradient"])}
  },
  "errors": {
    "404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Found. Please contact 4DMedical."])},
    "401": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorised. Please contact 4DMedical."])},
    "500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal Server Error. Please contact 4DMedical."])},
    "400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad Request. Please contact 4DMedical."])},
    "403": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbidden. Please contact 4DMedical."])},
    "502": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad Gateway. Please contact 4DMedical."])}
  }
}