import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "text-turquoise border-turquoise border-b text-body-2 py-4 4xl:text-heading-4" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = { class: "text-teal-dark py-4 text-caption 4xl:text-heading-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseImg = _resolveComponent("BaseImg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('footer.poweredBy')), 1),
      _createVNode(_component_BaseImg, {
        class: "w-36 m-auto 4xl:w-72",
        imageUrl: $setup.companyLogoWhite
      }, null, 8, ["imageUrl"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('footer.copyright')), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('footer.allRightsReserved')), 1)
    ])
  ]))
}