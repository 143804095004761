
import { defineComponent, computed } from 'vue';
import { useScanStore } from '@/stores/scan';
import AdvisorySection from './AdvisorySection.vue';

export default defineComponent({
  components: {
    AdvisorySection,
  },
  setup() {
    const scanStore = useScanStore();
    const getScan = computed(() => scanStore.getScan);

    return { getScan };
  },
});
