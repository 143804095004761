<template>
  <img :src="require(`@/assets/images/icons/${icon}.svg`)" @click="onClick" class="cursor-pointer" />
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  setup(_, { emit }) {
    const onClick = () => {
      emit('onClick');
    };

    return { onClick };
  },
});
</script>
