import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-wrap"
}
const _hoisted_2 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeader = _resolveComponent("BaseSubHeader")!
  const _component_BaseSubCard = _resolveComponent("BaseSubCard")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["group-metrics-container grid gap-2", _ctx.gridClass])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.metricsDataList, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(["single-metrics-container", item?.class])
      }, [
        _createVNode(_component_BaseSubCard, null, {
          default: _withCtx(() => [
            _createVNode(_component_BaseSubHeader, {
              title: item?.headerTitle,
              showInformationModal: _ctx.showInformationModal,
              informationModalText: item.informationModalText,
              markdown: true
            }, null, 8, ["title", "showInformationModal", "informationModalText"]),
            (item?.barDetails)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.barDetails, (barDetail, barIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(["bar-container", barDetail.class]),
                      key: barIndex
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["bar-value border-2 text-center", barDetail.valueClass])
                      }, _toDisplayString(barDetail.value) + " " + _toDisplayString(barDetail.symbol), 3),
                      _createElementVNode("div", _hoisted_2, _toDisplayString(barDetail.footerText), 1)
                    ], 2))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024)
      ], 2))
    }), 128))
  ], 2))
}