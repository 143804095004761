
import { computed, defineComponent, ref } from 'vue';
// @ts-ignore
import ColorbarImg from '@/assets/images/loading-2.jpg';

import ColorBar from '@/components/ColorbarComponent.vue';
import SliceComponent from '@/components/SliceComponent.vue';
import { useLabelsStore } from '@/stores/labels';

export default defineComponent({
  components: {
    ColorBar,
    SliceComponent,
  },
  props: {
    sliceHeader: {
      type: String,
      required: true,
    },
    slices: {
      type: Object,
      required: true,
    },
    showSlicedZoom: {
      type: Boolean,
      required: false,
      default: true,
    },
    onSubHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const zoomedImage = ref<string | null>(null);
    const labelsStore = useLabelsStore();
    const sliceZoomLabel = computed(() => labelsStore.getLabels?.rvImages?.zoom);

    const sliceOnClick = (slice: string) => {
      zoomedImage.value = slice;
    };

    return {
      zoomedImage,
      sliceOnClick,
      colorbarImg: ColorbarImg,
      sliceZoomLabel,
    };
  },
});
