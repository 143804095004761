<template>
  <div data-cy="specific-ventilation">
    <BaseHeader :title="svTitle" :showIcon="false" class="mb-2" />
    <BaseCard>
      <div class="grid grid-cols-12 gap-2">
        <div class="col-span-12 lg:col-span-8 xl:col-span-9 print:col-span-9">
          <div>
            <BaseSubCard>
              <div>
                <BaseSubHeader
                  :title="svhTitle"
                  :showInformationModal="showInformationModal"
                  :showTitle="showTitle"
                  :informationModalText="Information.SPECIFIC_VENTILATION_HISTOGRAM"
                />
                <p class="text-body-2 4xl:text-heading-5">{{ svDesc }}</p>
                <canvas class="print:!w-full print:!h-full" ref="ventDistChart"></canvas>
              </div>
            </BaseSubCard>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-4 xl:col-span-3 print:col-span-3">
          <MetricsList
            :metricsType="TabTypes.SPECIFIC_VENTILATION"
            :showInformationModal="showInformationModal"
          ></MetricsList>
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Information } from '@/constants/Information';
import { useInformationStore } from '@/stores/information';
import { useLabelsStore } from '@/stores/labels';

import { useScanStore } from '@/stores/scan';
import MetricsList from '@/components/ui/MetricsList.vue';
import { TabTypes } from '@/../src/constants/TabTypes';
import { drawChartLabels, getDefaultFontStyle } from '@/utility/helpers';

export default defineComponent({
  name: 'VentDistChart',
  components: { MetricsList },
  props: {
    showInformationModal: {
      type: Boolean,
      required: false,
      value: true,
      default: true,
    },
  },

  setup(props) {
    const scan = useScanStore();
    const informationStore = useInformationStore();
    const labelsStore = useLabelsStore();

    const getScan = computed(() => scan.getScan);
    const ventDistChart = ref(null);
    const svTitle = computed(() => labelsStore.getLabels?.specificVentilation?.sv);
    const svhTitle = computed(() => labelsStore.getLabels?.specificVentilation?.svh);
    const lvrTitle = computed(() => labelsStore.getLabels?.specificVentilation?.lvr);
    const hvrTitle = computed(() => labelsStore.getLabels?.specificVentilation?.hvr);

    const mvrTitle = computed(() => labelsStore.getLabels?.specificVentilation?.mvr);

    const svDesc = computed(() => labelsStore.getLabels?.specificVentilation?.SpecHistogramDesc);

    const xmin = ref(null);
    const xmax = ref(null);

    onMounted(() => {
      if (!getScan.value || !ventDistChart.value) {
        return;
      }

      const xData = getScan?.value?.specificVentilationHistogram?.data.map(({ x }) => x);
      const yData = getScan?.value?.specificVentilationHistogram?.data.map(({ y }) => y * 100);

      const delta = xData[1] - xData[0];
      xmin.value = xData[0];
      xmax.value = xData[xData.length - 1] + delta;

      Chart.register(annotationPlugin);
      getDefaultFontStyle();
      const ctx = ventDistChart.value.getContext('2d');

      if (!ctx) {
        return;
      }

      const gradient = {
        grey: ctx.createLinearGradient(0, 0, 0, 600),
        salmon: ctx.createLinearGradient(0, 0, 0, 600),
        blue: ctx.createLinearGradient(0, 0, 0, 600), // new blue gradient
      };

      gradient.grey.addColorStop(1, 'rgba(32, 32, 32, 1)');
      gradient.salmon.addColorStop(0, 'rgba(249,206,150,255)');
      gradient.blue.addColorStop(0, 'rgba(157,192,244,255)');

      const chartData = {
        labels: xData,
        datasets: [
          {
            data: yData,
            backgroundColor: gradient.grey,
            borderColor: 'rgb(32, 32, 32)',
          },
        ],
      };

      const chartAreaBorder = {
        type: 'box',
        xScaleID: 'x',
        yScaleID: 'y',
        backgroundColor: 'transparent',
        borderColor: 'rgb(105,105,105)',
        borderWidth: 2,
      };

      const annotationBox = {
        type: 'box',
        xScaleID: 'x',
        yScaleID: 'y',
        xMax: getScan.value.specificVentilationHistogram?.thresholdLow,
        backgroundColor: gradient.salmon,
        borderColor: 'transparent',
        borderWidth: 1,
        drawTime: 'beforeDatasetsDraw',
      };

      //paddings
      const middleLine = {
        type: 'line',
        xScaleID: 'x',
        yScaleID: 'y',
        xMin: getScan.value.specificVentilationHistogram?.averageVentilation,
        xMax: getScan.value.specificVentilationHistogram?.averageVentilation,
        borderColor: 'rgba(0, 0, 0, 1)', // Change color if needed
        borderWidth: 2,
        borderDash: [10, 5], // This makes the line dotted
      };

      const histogramChart = new Chart(ctx, {
        type: 'bar',
        data: chartData,
        options: {
          plugins: {
            autocolors: false,
            legend: { display: false },
            annotation: {
              annotations: [chartAreaBorder, annotationBox, middleLine],
            },
            customAxis: false,
          },
          scales: {
            x: {
              min: 0,
              max: xmax.value,
              display: true,
              type: 'linear',
              title: {
                display: true,
                text: 'Specific Ventilation',
              },
              offset: false,
              grid: {
                offset: false,
              },
            },

            y: {
              display: true,
              title: {
                display: true,
                text: 'Percentage',
              },
            },
          },
          events: [],
          layout: {
            padding: {
              bottom: 55,
            },
          },
        },
      });
    });

    return {
      ventDistChart,
      getScan,
      xmin,
      xmax,
      svTitle,
      svhTitle,
      svDesc,
      Information,
      lvrTitle,
      hvrTitle,
      mvrTitle,
      TabTypes,
    };
  },
});
</script>
