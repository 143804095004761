import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87b6915a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container p-3" }
const _hoisted_2 = {
  class: "gap-5",
  style: {"width":"125%"}
}
const _hoisted_3 = { class: "pt-8 flex-container orphans-3 clear-right text-justify" }
const _hoisted_4 = {
  class: "inline font-bold",
  style: {"break-after":"avoid"}
}
const _hoisted_5 = { class: "pt-8 flex-container orphans-3 clear-right text-justify" }
const _hoisted_6 = { class: "content" }
const _hoisted_7 = {
  class: "inline font-bold",
  style: {"break-after":"avoid"}
}
const _hoisted_8 = {
  style: {"text-align":"center"},
  class: "image"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "pt-8 flex-container orphans-3 clear-right text-justify" }
const _hoisted_11 = { class: "content" }
const _hoisted_12 = {
  class: "font-bold inline pt-2.5",
  style: {"break-after":"avoid"}
}
const _hoisted_13 = {
  style: {"text-align":"center"},
  class: "image"
}
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "pt-8 flex-container orphans-3 clear-right text-justify" }
const _hoisted_16 = { class: "font-bold inline pt-2.5" }
const _hoisted_17 = { class: "pt-8 flex-container orphans-3 clear-right text-justify" }
const _hoisted_18 = { class: "inline font-bold break-after-avoid" }
const _hoisted_19 = {
  key: 0,
  class: "pt-8 flex-container orphans-3 clear-right text-justify"
}
const _hoisted_20 = { class: "inline font-bold break-after-avoid" }
const _hoisted_21 = { key: 1 }
const _hoisted_22 = { class: "pt-8 flex-container orphans-3 clear-right text-justify" }
const _hoisted_23 = { class: "inline font-bold break-after-avoid" }
const _hoisted_24 = { class: "pt-8 flex-container orphans-3 clear-right text-justify" }
const _hoisted_25 = { class: "inline font-bold break-after-avoid" }
const _hoisted_26 = { class: "pt-8 flex-container orphans-3 clear-right text-justify" }
const _hoisted_27 = { class: "inline font-bold break-after-avoid" }
const _hoisted_28 = { key: 2 }
const _hoisted_29 = { class: "pt-8 flex-container orphans-3 clear-right text-justify" }
const _hoisted_30 = { class: "inline font-bold break-after-avoid" }
const _hoisted_31 = { class: "pt-8 flex-container orphans-3 clear-right text-justify" }
const _hoisted_32 = { class: "inline font-bold break-after-avoid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseHeader = _resolveComponent("BaseHeader")!
  const _component_InformationModal = _resolveComponent("InformationModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseHeader, {
      onSubHeader: false,
      title: _ctx.$t(_ctx.reportTypeTitle)
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.rvLabel), 1),
          _createVNode(_component_InformationModal, {
            markdownTitle: _ctx.Information.REGIONAL_VENTILATION_VISUALIZATION,
            preview: true,
            class: "break-after-avoid"
          }, null, 8, ["markdownTitle"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.vdhLabel), 1),
          _createVNode(_component_InformationModal, {
            markdownTitle: _ctx.Information.VENTILATION_GRAPH_HISTOGRAM,
            preview: true,
            style: {"break-before":"avoid"}
          }, null, 8, ["markdownTitle"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("img", {
            src: _ctx.getReportGuideImages?.histogramExample,
            style: {"width":"55mm","display":"inline-block"}
          }, null, 8, _hoisted_9)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.ventHetGuideLabel.guideHeader), 1),
          _createVNode(_component_InformationModal, {
            markdownTitle: _ctx.Information.VENTILATION_HETEROGENEITY,
            preview: true,
            class: "break-after-avoid"
          }, null, 8, ["markdownTitle"])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("img", {
            src: _ctx.getReportGuideImages?.visualizationExample,
            style: {"width":"55mm","display":"inline-block"}
          }, null, 8, _hoisted_14)
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.reportType === _ctx.ReportTypes.DAW ? _ctx.tvLabel : _ctx.lvLabel), 1),
          _createVNode(_component_InformationModal, {
            markdownTitle: _ctx.Information.TIDAL_VOLUME,
            preview: true,
            class: "break-after-avoid"
          }, null, 8, ["markdownTitle"])
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.rvSliceLabel), 1),
          _createVNode(_component_InformationModal, {
            markdownTitle: _ctx.Information.RVV_SLICES,
            preview: true,
            style: {"break-before":"avoid"}
          }, null, 8, ["markdownTitle"])
        ])
      ]),
      (_ctx.reportType === _ctx.ReportTypes.DAW)
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.videoFrameLabel), 1),
              _createVNode(_component_InformationModal, {
                markdownTitle: _ctx.Information.VIDEO_FRAMES,
                preview: true,
                style: {"break-before":"avoid"}
              }, null, 8, ["markdownTitle"])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.reportType === _ctx.ReportTypes.DAW && _ctx.getScan?.specificVentilationHistogram)
        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.svhLabel), 1),
                _createVNode(_component_InformationModal, {
                  markdownTitle: _ctx.Information.SPECIFIC_VENTILATION_HISTOGRAM,
                  preview: true,
                  class: "break-after-avoid"
                }, null, 8, ["markdownTitle"])
              ])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.lvrLabel), 1),
                _createVNode(_component_InformationModal, {
                  markdownTitle: _ctx.Information.SPECIFIC_VENTILATION_LVR,
                  preview: true,
                  class: "break-after-avoid"
                }, null, 8, ["markdownTitle"])
              ])
            ]),
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.msvLabel), 1),
                _createVNode(_component_InformationModal, {
                  markdownTitle: _ctx.Information.SPECIFIC_VENTILATION_MSV,
                  preview: true,
                  class: "break-after-avoid"
                }, null, 8, ["markdownTitle"])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.reportType === _ctx.ReportTypes.DAW && _ctx.getScan?.flowOscillatoryMetrics)
        ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_30, _toDisplayString(_ctx.fogLabel), 1),
                _createVNode(_component_InformationModal, {
                  markdownTitle: _ctx.Information.SPATIO_TEMPORAL_HETEROGENEITY_GRAPH,
                  preview: true,
                  style: {"break-before":"avoid"}
                }, null, 8, ["markdownTitle"])
              ])
            ]),
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.fosLabel), 1),
                _createVNode(_component_InformationModal, {
                  markdownTitle: _ctx.Information.SPATIO_TEMPORAL_HETEROGENEITY_SCORE,
                  preview: true,
                  style: {"break-before":"avoid"}
                }, null, 8, ["markdownTitle"])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}