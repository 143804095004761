
import { ref, onMounted, defineComponent } from 'vue';
import LoadingBarImg from '@/assets/images/loading-2.jpg';

export default defineComponent({
  props: {
    imageUrl: {
      type: String,
      required: true,
      default: '',
    },
    spinnerLoading: {
      type: Boolean,
      default: false,
    },
    emitHeight: {
      type: Boolean,
      default: false,
    },
    alt: String,
  },
  setup(props, context) {
    const image: any = ref(null);
    onMounted(() => {
      if (!props.emitHeight) {
        return;
      }

      let x = 0;

      const interval = setInterval(() => {
        const imageHeight = image.value ? image.value.naturalHeight : 0;

        if (imageHeight) {
          context.emit('imageHeight', imageHeight);
          clearInterval(interval);
        } else if (x >= 100) {
          clearInterval(interval);
        }

        x++;
      }, 50);
    });

    return { image, loadingBarImg: LoadingBarImg };
  },
});
