import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.spinnerLoading)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          ref: "image",
          class: "w-full h-full",
          src: _ctx.imageUrl?.startsWith('blob') ? _ctx.imageUrl : _ctx.loadingBarImg
        }, null, 8, _hoisted_1))
      : (_openBlock(), _createElementBlock("img", {
          key: 1,
          ref: "image",
          class: "w-full h-full",
          src: _ctx.imageUrl,
          alt: _ctx.alt
        }, null, 8, _hoisted_2))
  ]))
}