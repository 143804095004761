import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between px-4 py-1 bg-white text-blue 3xl:py-2 4xl:py-5" }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = {
  class: "text-grey",
  "data-cy": "scan-id"
}
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = {
  class: "text-grey",
  "data-cy": "date-acquired"
}
const _hoisted_6 = { class: "font-bold" }
const _hoisted_7 = {
  class: "text-grey",
  "data-cy": "date-prepared"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdvisorySection = _resolveComponent("AdvisorySection")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('mainHeader.scan')), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getScan.scanId), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('mainHeader.dateAcquired')), 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.getScan.dateAcquired), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('mainHeader.dateProcessed')), 1),
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.getScan.datePrepared), 1)
    ]),
    (_ctx.getScan.result.advisories)
      ? (_openBlock(), _createBlock(_component_AdvisorySection, {
          key: 0,
          advisories: _ctx.getScan.result.advisories
        }, null, 8, ["advisories"]))
      : _createCommentVNode("", true)
  ], 64))
}