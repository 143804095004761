import * as math from 'mathjs';
import { Chart } from 'chart.js';
import { FlowOscillatoryMetrics } from '@/types/Scan';
import { FLOW_CHART_CONSTANTS } from '@/constants/FlowChartConstants';
import { isPrintModeOpened } from '@/utility/helpers';

class FlowGraphConfig {
  w: number[];
  xMinMax: number[];
  yMinMax: number[];
  du: number;
  nBands: number;
  markerPoints: { x: number; y: number } | null;
  colorBands: string;
  colorBandLines: string;

  constructor(flowMetrics: FlowOscillatoryMetrics) {
    const { boundaries, rangeX, rangeY, upperSide, markerPoints } = flowMetrics;
    this.w = boundaries ?? [0, 0, 0];
    this.xMinMax = rangeX ?? [0, 0];
    this.yMinMax = rangeY ?? [0, 0];
    this.du = parseFloat(upperSide?.distanceToBoundary ?? '0');
    this.nBands = parseFloat(upperSide?.numOfColorBands ?? '0');
    this.markerPoints = markerPoints || null;
    this.colorBands = upperSide?.colorBands ?? 'On';
    this.colorBandLines = upperSide?.colorBandLines ?? 'On';
  }
}

class ScanAnalyzer {
  FlowGraphConfig: FlowGraphConfig;

  static readonly WHITE = ScanAnalyzer.hexToRgb('#FFFFFF');
  static readonly BASE_COLOR = ScanAnalyzer.hexToRgb('#FFC0CB');
  static readonly END_COLOR = ScanAnalyzer.hexToRgb('#FF0000');

  constructor(flowMetrics: FlowOscillatoryMetrics) {
    this.FlowGraphConfig = new FlowGraphConfig(flowMetrics);
  }

  calculateInitialLine() {
    const { w, xMinMax } = this.FlowGraphConfig;
    const findYOne = -(w[0] + w[1] * xMinMax[0]) / w[2];
    const findXOne = -(w[0] + w[2] * 0) / w[1];
    const lineOne = [
      { x: xMinMax[0], y: findYOne },
      { x: findXOne, y: 0 },
    ];

    const lowestX = Math.min(...lineOne.map((point) => point.x));
    const lowestY = Math.min(...lineOne.map((point) => point.y));
    const highestX = Math.max(...lineOne.map((point) => point.x));
    const highestY = Math.max(...lineOne.map((point) => point.y));

    return { lineOne, lowestX, lowestY, highestX, highestY };
  }

  findLineEndpoints() {
    const { w, xMinMax, yMinMax } = this.FlowGraphConfig;
    const ptsX = [];
    const ptsY = [];

    for (let i = 0; i < 2; i++) {
      const x = -(w[0] + w[2] * yMinMax[i]) / w[1];

      if (x > xMinMax[0] && x < xMinMax[1]) {
        ptsX.push(x);
        ptsY.push(yMinMax[i]);
      }
    }

    for (let i = 0; i < 2; i++) {
      const y = -(w[0] + w[1] * xMinMax[i]) / w[2];

      if (y > yMinMax[0] && y < yMinMax[1]) {
        ptsX.push(xMinMax[i]);
        ptsY.push(y);
      }
    }

    return [ptsX, ptsY];
  }

  findNewBoundaryUsingDistance() {
    const { w, xMinMax, yMinMax, du, nBands } = this.FlowGraphConfig;
    const [xEndPts, yEndPts] = this.findLineEndpoints();
    const pt1 = [xEndPts[0], yEndPts[0]];
    const pt2 = [xEndPts[1], yEndPts[1]];
    let ptc = [0.5 * (pt1[0] + pt2[0]), 0.5 * (pt1[1] + pt2[1])];
    let w0_new = 0;

    const step_sz = du / (nBands - 1);
    let curr_step = step_sz;

    while (curr_step <= du) {
      const v = pt1[0] > pt2[0] ? math.subtract(pt1, ptc) : math.subtract(pt2, ptc);
      const v_unit = math.divide(v, math.norm(v));

      const phi = 90;
      const phi_r = math.unit(phi, 'deg').toNumber('rad');
      const cos_phi = Math.cos(phi_r);
      const sin_phi = Math.sin(phi_r);

      const R = math.matrix([
        [cos_phi, -sin_phi],
        [sin_phi, cos_phi],
      ]);

      const normalMatrix = math.multiply(R, v_unit);
      const normalArray = normalMatrix.toArray ? normalMatrix.toArray() : normalMatrix.valueOf();
      const ptc_new = math.add(math.multiply(normalArray, step_sz), ptc) as number[];

      w0_new = -(w[1] * ptc_new[0] + w[2] * ptc_new[1]);
      ptc = ptc_new;
      curr_step += step_sz;
    }

    return [w0_new, w[1], w[2]];
  }

  calculateSecondLine(highestX: number, highestY: number) {
    const { w, xMinMax, yMinMax, du, nBands } = this.FlowGraphConfig;
    const wnew = this.findNewBoundaryUsingDistance();
    const findY = -(wnew[0] + wnew[1] * highestX) / wnew[2];
    const findX = -(wnew[0] + wnew[2] * highestY) / wnew[1];
    const lineTwo = [
      { x: findX, y: highestY },
      { x: highestX, y: findY },
    ];

    return { lineTwo, wnew };
  }
  static interpolateColor(color1: number[], color2: number[], factor = 0.5) {
    const result = color1.slice();
    for (let i = 0; i < 3; i++) {
      result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
    }
    return result;
  }

  static hexToRgb(hex: string) {
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  }

  static rgbToHex(rgb: number[]) {
    return (
      '#' +
      rgb
        .map((x) => {
          const hex = x.toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        })
        .join('')
    );
  }

  static calculatePoints(
    start1: number,
    end1: number,
    start2: number,
    end2: number,
    bandHeight: number,
    i: number,
    totalDistance: number,
  ) {
    const start = start1 + (i * bandHeight * (start2 - start1)) / totalDistance;
    const end = end1 + (i * bandHeight * (end2 - end1)) / totalDistance;
    return { start, end };
  }

  private getColorForIndex(index: number, numColorBands: number): string {
    let color;
    if (index === 0) {
      return ScanAnalyzer.rgbToHex(ScanAnalyzer.interpolateColor(ScanAnalyzer.WHITE, ScanAnalyzer.BASE_COLOR, 0));
    } else {
      return ScanAnalyzer.rgbToHex(
        ScanAnalyzer.interpolateColor(
          ScanAnalyzer.BASE_COLOR,
          ScanAnalyzer.END_COLOR,
          (index - 1) / (numColorBands - 1),
        ),
      );
    }
  }

  drawColorBands(chart: Chart, lineOne: any[], lineTwo: any[], colorBand: string, colorBandLines: string): void {
    const ctx = chart.ctx;
    const yAxis = chart.scales.y;
    const xAxis = chart.scales.x;

    const line1StartY = yAxis.getPixelForValue(lineOne[0]?.y);
    const line1EndY = yAxis.getPixelForValue(lineOne[1]?.y);
    const line1StartX = xAxis.getPixelForValue(lineOne[0]?.x);
    const line1EndX = xAxis.getPixelForValue(lineOne[1]?.x);

    const line2StartY = yAxis.getPixelForValue(lineTwo[0]?.y);
    const line2EndY = yAxis.getPixelForValue(lineTwo[1]?.y);
    const line2StartX = xAxis.getPixelForValue(lineTwo[0]?.x);
    const line2EndX = xAxis.getPixelForValue(lineTwo[1]?.x);

    const totalDistance = Math.hypot(line2StartY - line1StartY, line2StartX - line1StartX);
    const numColorBands = this.FlowGraphConfig.nBands;
    if (!numColorBands) {
      return;
    }
    const bandHeight = totalDistance / numColorBands;

    for (let i = 0; i < numColorBands; i++) {
      const color = this.getColorForIndex(i, numColorBands);

      ctx.fillStyle = color;

      const { start: x1Start, end: x1End } = ScanAnalyzer.calculatePoints(
        line1StartX,
        line1EndX,
        line2StartX,
        line2EndX,
        bandHeight,
        i,
        totalDistance,
      );
      const { start: x2Start, end: x2End } = ScanAnalyzer.calculatePoints(
        line1StartX,
        line1EndX,
        line2StartX,
        line2EndX,
        bandHeight,
        i + 1,
        totalDistance,
      );

      const { start: y1Start, end: y1End } = ScanAnalyzer.calculatePoints(
        line1StartY,
        line1EndY,
        line2StartY,
        line2EndY,
        bandHeight,
        i,
        totalDistance,
      );
      const { start: y2Start, end: y2End } = ScanAnalyzer.calculatePoints(
        line1StartY,
        line1EndY,
        line2StartY,
        line2EndY,
        bandHeight,
        i + 1,
        totalDistance,
      );

      if (colorBand === 'On') {
        ctx.beginPath();
        ctx.moveTo(x1Start, y1Start);
        ctx.lineTo(x1End, y1End);
        ctx.lineTo(x2End, y2End);
        ctx.lineTo(x2Start, y2Start);
        ctx.closePath();
        ctx.fill();
      }
      if (colorBandLines === 'On') {
        ctx.setLineDash([5, 5]);
        ctx.strokeStyle = '#000000';
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(x1Start, y1Start);
        ctx.lineTo(x1End, y1End);
        ctx.stroke();
        ctx.setLineDash([]);
      }
    }
    if (colorBandLines === 'On') {
      ctx.setLineDash([5, 5]);
      ctx.strokeStyle = '#000000';
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(line2StartX, line2StartY);
      ctx.lineTo(line2EndX, line2EndY);
      ctx.stroke();
      ctx.setLineDash([]);
    }
    if (colorBand === 'On') {
      ctx.fillStyle = '#FF0000';
      ctx.beginPath();
      ctx.moveTo(line2StartX, line2StartY);
      ctx.lineTo(line2EndX, line2EndY);
      ctx.lineTo(line2EndX, chart.chartArea.top);
      ctx.lineTo(line2StartX, chart.chartArea.top);
      ctx.closePath();
      ctx.fill();
    }
  }

  drawCustomAxis(chart: Chart, colorBands: string) {
    if (colorBands === 'On') {
      const ctx = chart.ctx;
      const chartArea = chart.chartArea;

      const numColorBands = this.FlowGraphConfig.nBands;
      if (!numColorBands) {
        return;
      }

      const colorBarHeight = FLOW_CHART_CONSTANTS.HEIGHT_CUSTOM_AXIS;
      const colorBarMarginTop = 60;
      const colorBarWidth = (chartArea.right - chartArea.left) * 0.5;
      const colorBarX = chartArea.left + (chartArea.right - chartArea.left - colorBarWidth) / 2;

      const colorBarCanvas = document.getElementById('colorBarCanvas') as HTMLCanvasElement | null;
      if (!colorBarCanvas) {
        console.log('Failed to get color bar canvas element');
        return;
      }

      colorBarCanvas.width = colorBarWidth;
      colorBarCanvas.height = colorBarHeight;
      const colorBarCtx = colorBarCanvas.getContext('2d');
      if (!colorBarCtx) {
        console.error('Failed to get 2D context for color bar canvas');
        return;
      }

      // Draw color bands
      for (let i = 0; i < numColorBands; i++) {
        const color = this.getColorForIndex(i, numColorBands);

        colorBarCtx.fillStyle = color;
        const rectX = i * (colorBarWidth / numColorBands);
        const rectWidth = colorBarWidth / numColorBands;
        colorBarCtx.fillRect(rectX, 0, rectWidth, colorBarHeight);

        colorBarCtx.strokeStyle = '#000000';
        colorBarCtx.lineWidth = 2;
        colorBarCtx.strokeRect(0, 0, colorBarWidth, colorBarHeight);
      }

      // Draw the color bar onto the chart's canvas
      ctx.drawImage(colorBarCanvas, colorBarX, chartArea.bottom + colorBarMarginTop, colorBarWidth, colorBarHeight);

      // Draw text labels
      ctx.fillStyle = '#000000';
      ctx.font = '14px';
      const lowPositionShifter = isPrintModeOpened() ? 40 : 30;
      ctx.fillText('Low', colorBarX - lowPositionShifter, chartArea.bottom + colorBarMarginTop + colorBarHeight);
      ctx.fillText('High', colorBarX + colorBarWidth + 5, chartArea.bottom + colorBarMarginTop + colorBarHeight);

      ctx.restore();
    }
  }

  drawMarkerPoint(chart: Chart) {
    const ctx = chart.ctx;
    const xAxis = chart.scales.x;
    const yAxis = chart.scales.y;

    const { markerPoints } = this.FlowGraphConfig;
    const xData = markerPoints?.x ?? 0;
    const yData = markerPoints?.y ?? 0;

    const xPixel = xAxis.getPixelForValue(xData);
    const yPixel = yAxis.getPixelForValue(yData);

    const defaultFont = ctx.font;

    ctx.fillStyle = 'black';
    ctx.font = '3.5vh Arial';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(FLOW_CHART_CONSTANTS.SIGN, xPixel, yPixel);
    ctx.font = defaultFont;
  }
}

export default ScanAnalyzer;
