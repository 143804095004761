
import { reactive, computed, defineComponent } from 'vue';
import BaseImg from '@/components/ui/BaseImg.vue';
import { useI18n } from 'vue-i18n';

const DEFAULT_IMAGE_HEIGHT = 600;

export default defineComponent({
  components: {
    BaseImg,
  },
  props: {
    imageUrl: {
      type: String,
      default: null,
    },
    alt: String,
    showGuides: {
      type: Boolean,
      default: false,
    },
    guides: {
      type: Object,
    },
  },
  setup(props) {
    const guidePositions = reactive({
      upper: 0,
      centre: 0,
      lower: 0,
    });
    const { t } = useI18n();
    const setTopPostion = (val: any) => `top: ${val}%`;
    const getUpperTop = computed(() => setTopPostion(guidePositions.upper));
    const getCentreTop = computed(() => setTopPostion(guidePositions.centre));
    const getLowerTop = computed(() => setTopPostion(guidePositions.lower));
    const position = {
      upper: t('positions.upper'),
      middle: t('positions.middle'),
      lower: t('positions.lower'),
    };
    const getTopPercent = (zPosition: any, imageHeight: any) => {
      if (!zPosition) {
        return 0;
      }

      const heightRatio = parseFloat(zPosition) / imageHeight;
      return heightRatio * 100;
    };

    const imageHeightUpdate = (height: any) => {
      if (!props.showGuides) {
        return;
      }

      const imageHeight = height || DEFAULT_IMAGE_HEIGHT;
      guidePositions.upper = getTopPercent((props as any).guides.axial_upper_z_position_px, imageHeight);
      guidePositions.centre = getTopPercent((props as any).guides.axial_centre_z_position_px, imageHeight);
      guidePositions.lower = getTopPercent((props as any).guides.axial_lower_z_position_px, imageHeight);
    };

    return {
      guidePositions,
      getUpperTop,
      getCentreTop,
      getLowerTop,
      getTopPercent,
      imageHeightUpdate,
      position,
    };
  },
});
