export const parseDateToRequiredFormatForReport = (date: string, includeTime: boolean) => {
  const dateObject = new Date(date);
  const dateComponents = dateObject.toISOString().split('T');
  if (includeTime) {
    return (
      getReportDateFormat(dateComponents[0]) +
      ' ' +
      dateComponents[1].substring(0, dateComponents[1].lastIndexOf('.')) +
      ' UTC'
    );
  } else {
    return getReportDateFormat(dateComponents[0]) + ' UTC';
  }
};

function getReportDateFormat(dateString: string) {
  return new Date(dateString)
    .toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })
    .replace(/ /g, '-')
    .toUpperCase();
}
