
import CompanyLogoWhite from '@/assets/images/company-logo-white.png';

export default {
  setup() {
    const companyLogoWhite = CompanyLogoWhite;

    return { companyLogoWhite };
  },
};
