module.exports = {
  purge: ['./src/**/*.vue', './src/**/*.css'],
  content: ['./src/**/*.{html,js}'],
  darkMode: false, // or "media" or "class"
  theme: {
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      blue: {
        DEFAULT: '#215b7d',
        dark: '#0a2855',
        darker: '#0b053d',
      },
      teal: {
        DEFAULT: '#2dbeb4',
        dark: '#087482',
      },
      turquoise: {
        light: '#ccecec',
        DEFAULT: '#9fcfca',
      },
      black: '#212529',
      white: '#ffffff',
      'off-white': '#f2f2f2',
      grey: {
        lightest: '#e9e9ea',
        light: '#d3d3d4',
        DEFAULT: '#7a7c7f',
        dark: '#a6a8a9',
        darkest: '#4d5154',
      },
      yellow: {
        light: '#fcff36',
        DEFAULT: '#faCC15',
      },
      red: {
        light: '#f87171',
        DEFAULT: '#ef4444',
      },
      error: '#eb0045',
      warning: '#ffa400',
      success: '#4bb543',
      info: '#256ad1',
      pink: '#feccc6',
      orange: '#f9ce96',
    },
    fontSize: {
      'heading-1': '3.18rem',
      'heading-2': '2.81rem',
      'heading-3': '2.5rem',
      'heading-4': '2rem',
      'heading-5': '1.56rem',
      'heading-6': '1.25rem',
      'heading-7': '1.12rem',
      'body-1': '1rem',
      'body-2': '0.87rem',
      caption: '0.75rem',
      reportCustom: '0.75rem',
      overline: '0.62rem',
      customSmall: '0.50rem',
      navBar: '1.3vh',
    },
    extend: {
      spacing: {
        76: '19rem',
        77: '19.25rem',
        78: '19.5rem',
        38: '9.5rem',
        50: '12.5rem',
        26: '6.5rem',
        900: '95rem',
        700: '77rem',
        500: '55rem',
        560: '48rem',
        480: '41rem',
        440: '40rem',
        420: '36rem',
        400: '32rem',
        200: '23rem',
        41: '10.5rem',
      },
      typography: (theme) => ({
        DEFAULT: {
          css: {
            p: {
              orphans: '3',
            },
          },
        },
      }),
      clear: {
        right: 'right',
      },
      opacity: {
        test: '0.0',
      },
      gridTemplateColumns: {
        14: 'repeat(14, minmax(20px, 190px))',
        18: 'repeat(18, minmax(30px, 150px))',
        23: 'repeat(23, minmax(50px, 100px))',
        19: 'repeat(19, minmax(0, 1fr))',
      },
      fontFamily: {
        roboto: ['Roboto'],
      },
      screens: {
        '3xl': '1920px',
        '4xl': '3840px',
        a4: '525px',
      },
    },
  },
  variants: {},
  safelist: [
    'w-[32%]',
    'mr-[2%]',
    'xl:mr-[1%]',
    'mr-[1%]',
    'xl:w-[49%], w-[49%], lg:w-full',
    'lg:mr-0',
    'col-span-2',
    'bg-orange',
    'bg-pink',
    'print:w-full',
    'lg:w-full',
    'border-teal',
    'border-black',
    'border-dashed',
    'sm:grid-cols-2',
    'lg:grid-cols-1',
    'print:grid-cols-1',
  ],
  plugins: [require('@tailwindcss/typography')],
};
