import { MetricsDetailService } from './MetricsDetailService';
import { Information } from '@/constants/Information';
import { useLabelsStore } from '@/stores/labels';
import { useScanStore } from '@/stores/scan';
import { useI18n } from 'vue-i18n';
import { MetricsDetails } from '@/types/Metrics';
import { ReportTypes } from '@/constants/ReportTypes';

export class ReportSummaryMetricsDetailService implements MetricsDetailService {
  gridClass = 'grid-cols-2 mt-1';
  metricsDataList: MetricsDetails[] = [];
  labelsStore = useLabelsStore().getLabels;
  scanStore = useScanStore().getScan;
  scanMetrics = useScanStore().getScan?.scanMetrics;
  translate = useI18n();

  getMetricsData() {
    const product = this.scanStore?.product;
    if (product === ReportTypes.DAW) {
      this.metricsDataList.push(this.getTidalVolume());
    } else if (product === ReportTypes.EWD) {
      this.metricsDataList.push(this.getLungVolumes());
    }
    this.metricsDataList.push(this.getVdpData());
    this.metricsDataList.push(this.getVentilationHeterogeneity());
  }
  getVdpData(): MetricsDetails {
    const product = this.scanStore?.product;
    return {
      headerTitle: this.labelsStore?.vdp || '',
      informationModalText: Information.VENTILATION_GRAPH_HISTOGRAM,
      class: product === ReportTypes.DAW ? '' : 'col-span-2',
      barDetails: [
        {
          value: this.scanStore?.histogram?.vdp.toString() || '',
          class: 'w-full',
          symbol: '%',
          valueClass: 'bg-pink border-teal',
        },
      ],
    };
  }

  getVentilationHeterogeneity(): MetricsDetails {
    const reportSummaryLabel = this.labelsStore?.reportSummary?.metrics?.vh;
    const ventilationHeterogeneity = this.scanMetrics?.ventilationHeterogeneity;
    return {
      headerTitle: reportSummaryLabel?.heading || '',
      informationModalText: Information.VENTILATION_HETEROGENEITY,
      class: 'col-span-2',
      barDetails: [
        {
          value: ventilationHeterogeneity?.smallScale?.toFixed(1) || '',
          class: 'w-[32%] mr-[2%]',
          footerText: reportSummaryLabel?.subHeadingSmall || '',
          valueClass: 'border-teal',
        },
        {
          value: ventilationHeterogeneity?.overall?.toFixed(1) || '',
          class: 'w-[32%] mr-[2%]',
          footerText: reportSummaryLabel?.subHeadingTotal,
          valueClass: 'border-teal',
        },
        {
          value: ventilationHeterogeneity?.largeScale?.toFixed(1) || '',
          class: 'w-[32%]',
          footerText: reportSummaryLabel?.subHeadingLarge,
          valueClass: 'border-teal',
        },
      ],
    };
  }

  getTidalVolume(): MetricsDetails {
    return {
      headerTitle: this.labelsStore?.tv || '',
      informationModalText: Information.TIDAL_VOLUME,
      barDetails: [
        {
          value: this.scanStore?.scanMetrics?.tidalVolumeQuant?.toFixed(2).toString() || '',
          class: 'w-full',
          symbol: 'L',
          valueClass: 'border-teal',
        },
      ],
    };
  }

  getLungVolumes(): MetricsDetails {
    const scanMetrics = this.scanStore?.scanMetrics;
    return {
      headerTitle: this.labelsStore?.lv || '',
      informationModalText: Information.TIDAL_VOLUME,
      class: 'col-span-2',
      barDetails: [
        {
          value: scanMetrics?.inspirationVolume?.toFixed(1) || '',
          class: 'w-[32%] mr-[2%]',
          symbol: 'L',
          footerText: this.labelsStore?.iv || '',
          valueClass: 'border-teal',
        },
        {
          value: scanMetrics?.expirationVolume?.toFixed(1) || '',
          class: 'w-[32%] mr-[2%]',
          symbol: 'L',
          footerText: this.labelsStore?.ev || '',
          valueClass: 'border-teal',
        },
        {
          value: scanMetrics?.volumeChange?.toFixed(1) || '',
          class: 'w-[32%]',
          symbol: 'L',
          footerText: this.labelsStore?.vc || '',
          valueClass: 'border-teal',
        },
      ],
    };
  }
}
