<template>
  <BaseSubCardContact>
    <div class="grid grid-cols-2">
      <div class="col-start-1 justify-self-start">
        <BaseImg class="h-8 3xl:h-12 4xl:h-20" :imageUrl="img.logo" alt="logo" />
      </div>
      <div class="grid grid-cols-3 col-start-2 justify-self-end space-x-2 h-1/2 w-1/2">
        <BaseImg class="col-start-1 h-5/6 justify-self-center" :imageUrl="img.md" alt="md" />
        <BaseImg class="col-start-2 h-5/6 w-5/6 justify-self-center" :imageUrl="img.rx" alt="rx" />
        <BaseImg class="col-start-3 justify-self-center" :imageUrl="img.ifu" alt="ifu" />
        <div class="col-start-3 text-center text-[0.5em]">Electronically Available</div>
      </div>
    </div>

    <div class="flex my-3">
      <BaseImg class="mr-2 h-10 3xl:h-12 4xl:h-24" :imageUrl="img.udi" />
      <div class="flex-auto p-2 tracking-wider text-center border-2 border-grey-lightest">
        <span class="inline-flex">{{ udiInfo.id }}</span>
        <span class="inline-flex">{{ udiInfo.version }}</span>
        <span class="inline-flex">{{ udiInfo.releaseDate }}</span>
        <span class="inline-flex">{{ udiInfo.serialNumber }}</span>
      </div>
    </div>

    <div class="flex justify-between">
      <div class="w-1/2">
        <BaseImg class="w-14 3xl:w-16 4xl:w-28" :imageUrl="img.fillFcty" alt="fillFcty" />

        <div>{{ contact?.companyName }}</div>
        <p class="whitespace-pre-wrap">{{ contact?.ManufacturerDetails }}</p>
      </div>
      <div class="text-right flex flex-col items-end">
        <BaseImg class="w-14 3xl:w-16 4xl:w-28" :imageUrl="img.unFillFcty" alt="unFillFcty" />
        <p>{{ contactPageLabel.version }}: {{ scan.version }}</p>
        <p>{{ contactPageLabel.releaseDate }}: {{ releaseDate }}</p>
        <p>{{ contactPageLabel.serialNumber }}: {{ scan.serialNumber }}</p>
      </div>
    </div>
  </BaseSubCardContact>
</template>

<script>
import medicalLogo from '../../assets/images/company-logo.png';

import udi from '../../assets/images/iso-udi.png';
import fillFcty from '../../assets/images/iso-manufacturer.png';
import unFillFcty from '../../assets/images/iso-dateofmanufacture.png';
import md from '../../assets/images/iso-md.png';
import ifu from '../../assets/images/iso-ifu.png';
import rx from '../../assets/images/iso-rx.png';

import { computed } from 'vue';
import { useContactStore } from '@/stores/contact';
import { useScanStore } from '@/stores/scan';
import { useLabelsStore } from '@/stores/labels';
import dayjs from 'dayjs';

export default {
  setup() {
    const contactStore = useContactStore();
    const scanStore = useScanStore();
    const labelsStore = useLabelsStore();
    const contactPageLabel = computed(
      () =>
        labelsStore.getLabels?.contact4DM.label || {
          version: '',
          releaseDate: '',
          serialNumber: '',
        },
    );

    const img = {
      logo: medicalLogo,
      md,
      ifu,
      rx,
      fillFcty,
      unFillFcty,
      udi,
    };

    const contact = computed(() => contactStore.getContact);
    const scan = computed(() => scanStore.getScan);
    const udiInfo = computed(() => {
      return {
        id: `(01)${scan.value.deviceIdentifier.value}`,
        version: `(10)${scan.value.version}`,
        releaseDate: `(11)${dayjs(scan.value.releaseDate).format('YYMMDD')}`,
        serialNumber: `(21)${scan.value.serialNumber}`,
      };
    });
    const releaseDate = computed(() => dayjs(scan.value.releaseDate).format('YYYY-MM-DD'));

    return { img, contact, udiInfo: udiInfo, scan, releaseDate, contactPageLabel };
  },
};
</script>
