import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex mb-2" }
const _hoisted_2 = { class: "flex mb-2" }
const _hoisted_3 = { class: "flex-auto" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "flex mb-2" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "flex items-start" }
const _hoisted_9 = { class: "whitespace-pre" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhoneIcon = _resolveComponent("PhoneIcon")!
  const _component_AtSymbolIcon = _resolveComponent("AtSymbolIcon")!
  const _component_GlobeAltIcon = _resolveComponent("GlobeAltIcon")!
  const _component_MailIcon = _resolveComponent("MailIcon")!
  const _component_BaseSubCardContact = _resolveComponent("BaseSubCardContact")!

  return ($setup.contact)
    ? (_openBlock(), _createBlock(_component_BaseSubCardContact, {
        key: 0,
        class: "px-5"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_PhoneIcon, { class: "w-4 3xl:w-5 4xl:w-10 mr-2" }),
            _createElementVNode("p", null, _toDisplayString($setup.contact.phone), 1)
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_AtSymbolIcon, { class: "w-4 3xl:w-5 4xl:w-10 mr-2" }),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("a", {
                href: `mailto:${$setup.contact.email}`,
                class: "mr-3"
              }, _toDisplayString($setup.contact.email), 9, _hoisted_4),
              _createTextVNode(" | "),
              _createElementVNode("a", {
                href: $setup.contact.supportUrl,
                class: "ml-3"
              }, _toDisplayString($setup.contact.supportUrl), 9, _hoisted_5)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_GlobeAltIcon, { class: "w-4 3xl:w-5 4xl:w-10 mr-2" }),
            _createElementVNode("a", {
              href: $setup.contact.website
            }, _toDisplayString($setup.contact.website), 9, _hoisted_7)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_MailIcon, { class: "w-4 3xl:w-5 4xl:w-10 pt-2 mr-2" }),
            _createElementVNode("p", _hoisted_9, _toDisplayString($setup.contact.supportAddress), 1)
          ])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}