<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import AppLayoutDefault from './AppLayoutDefault';
import NoLayout from './NoLayout';
import { shallowRef, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: 'AppLayout',
  setup() {
    const layout = shallowRef(NoLayout);
    const route = useRoute();

    watchEffect(async () => {
      try {
        if (route.matched.length) {
          if (route.meta.layout === undefined) {
            layout.value = AppLayoutDefault;
          }
        } else {
          layout.value = NoLayout;
        }
      } catch (e) {
        layout.value = AppLayoutDefault;
      }
    });

    return { layout };
  },
};
</script>
