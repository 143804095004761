
import { ReportTypes } from '@/../src/constants/ReportTypes';

import { computed, defineComponent, onMounted } from 'vue';
import { Information } from '@/constants/Information';
import { useInformationStore } from '@/stores/information';
import InformationModal from '@/components/InformationModal.vue';
import { useScanStore } from '@/stores/scan';
import { useLabelsStore } from '@/stores/labels';

export default defineComponent({
  components: {
    InformationModal,
  },
  props: {
    reportType: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const informationStore = useInformationStore();
    const scanStore = useScanStore();
    const labelsStore = useLabelsStore();
    const ventHetGuideLabel = computed(() => labelsStore.getLabels?.reportSummary.metrics.vh || { guideHeader: '' });
    const rvLabel = computed(() => labelsStore.getLabels?.rv);
    const vdhLabel = computed(() => labelsStore.getLabels?.vdh);
    const rvSliceLabel = computed(() => labelsStore.getLabels?.rvSlice);
    const videoFrameLabel = computed(() => labelsStore.getLabels?.vent4DVideo?.previewHeader);

    const svhLabel = computed(() => labelsStore.getLabels?.specificVentilation?.svh);
    const lvrLabel = computed(() => labelsStore.getLabels?.specificVentilation?.lvr);
    const msvLabel = computed(() => labelsStore.getLabels?.specificVentilation?.msv);
    const fogLabel = computed(() => labelsStore.getLabels?.flowOscillatory?.fog);
    const fosLabel = computed(() => labelsStore.getLabels?.flowOscillatory?.fos);
    const tvLabel = computed(() => labelsStore.getLabels?.tv);
    const lvLabel = computed(() => labelsStore.getLabels?.lv);
    const getReportGuideImages = computed(() =>
      informationStore.getReportGuideImages ? informationStore.getReportGuideImages : {},
    );

    const reportTypeTitle = computed(() =>
      props.reportType === ReportTypes.DAW ? 'reportPreview.readReport' : 'reportPreview.readReportEWD',
    );

    const getScan = computed(() => scanStore.getScan);

    onMounted(async () => {
      const resp = await informationStore.getReportGuideImagesRequest(getScan.value?.resultId, getScan.value!.product);
    });

    return {
      Information,
      ReportTypes,
      getReportGuideImages,
      reportTypeTitle,
      ventHetGuideLabel,
      rvLabel,
      vdhLabel,
      rvSliceLabel,
      videoFrameLabel,
      tvLabel,
      lvLabel,
      svhLabel,
      lvrLabel,
      msvLabel,
      fosLabel,
      fogLabel,
      getScan,
    };
  },
});
