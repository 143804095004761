import { useToast } from 'vue-toastification';

export const useNotification = () => {
  const toast = useToast();

  const showError = (detail: string | number) => {
    toast.error('Something went wrong. Please contact 4DMedical.', {
      timeout: false,
    });
  };

  return {
    showError,
  };
};
