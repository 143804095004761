import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "font-semibold text-blue-darker flex justify-between text-heading-6 3xl:text-heading-4 4xl:text-heading-1" }
const _hoisted_2 = ["src", "data-cy"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InformationModal = _resolveComponent("InformationModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", null, _toDisplayString(_ctx.title), 1),
    (_ctx.showIcon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.informationIcon,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeDialogState())),
          "data-cy": 'information-' + _ctx.title,
          class: "w-7 3xl:w-11 4xl:w-20"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createVNode(_component_InformationModal, {
      modelValue: _ctx.dialogState,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dialogState) = $event)),
      title: _ctx.title,
      markdownTitle: _ctx.markdownTitle,
      dialogState: _ctx.dialogState
    }, null, 8, ["modelValue", "title", "markdownTitle", "dialogState"])
  ]))
}