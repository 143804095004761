<template>
  <BaseSubCard>
    <div>
      <BaseSubHeader
        :title="vdhLabel"
        :showInformationModal="showInformationModal"
        :informationModalText="Information.VENTILATION_GRAPH_HISTOGRAM"
        :imageGuide="getReportGuideImages.histogramExample"
        :markdown="true"
      />
      <p class="text-body-2 4xl:text-heading-5">{{ histogramDescLabel }}</p>
      <canvas class="print:!w-full print:!h-full" ref="ventDistChart"></canvas>
    </div>
  </BaseSubCard>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';

import { Information } from '@/constants/Information';
import { useScanStore } from '@/stores/scan';
import { useInformationStore } from '@/stores/information';
import { useLabelsStore } from '@/stores/labels';
import { drawChartLabels, getDefaultFontStyle } from '@/utility/helpers';

export default defineComponent({
  name: 'VentDistChart',
  props: {
    VDP: {
      type: Number,
      required: true,
    },
    showInformationModal: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const scan = useScanStore();
    const information = useInformationStore();
    const getReportGuideImages = computed(() => information.getReportGuideImages);
    const getScan = computed(() => scan.getScan);
    const ventDistChart = ref(null);
    const xmin = ref(null);
    const xmax = ref(null);

    const labelsStore = useLabelsStore();
    const vdhLabel = computed(() => labelsStore.getLabels?.vdh);
    const histogramDescLabel = computed(() => labelsStore.getLabels?.histogramDesc);

    onMounted(() => {
      if (!getScan.value || !ventDistChart.value) {
        return;
      }

      const xData = getScan.value.histogram.data.map(({ x }) => x.toFixed(2));
      const yData = getScan.value.histogram.data.map(({ y }) => y * 100);

      const sortedData = getScan.value.histogram.data.sort((a, b) => a.x - b.x);

      xmin.value = sortedData[0].x;
      xmax.value = sortedData[sortedData.length - 1].x + (sortedData[1].x - sortedData[0].x);

      xData.push(xmax.value);
      const legend = 'VDP';

      Chart.register(annotationPlugin);
      getDefaultFontStyle();

      const gradientPlugin = {
        id: 'gradientBackground',
        beforeDraw: (chart) => {
          const { ctx, chartArea } = chart;
          const gradient = ctx.createLinearGradient(
            chartArea.left,
            chartArea.bottom,
            chartArea.right,
            chartArea.bottom,
          );
          gradient.addColorStop(0, 'red');
          gradient.addColorStop(0.25, 'yellow');
          gradient.addColorStop(0.5, '#7FFF00'); // Use a lighter shade of green
          gradient.addColorStop(0.75, 'cyan');
          gradient.addColorStop(1, 'blue');

          ctx.save();
          ctx.fillStyle = gradient;
          ctx.fillRect(chartArea.left, chartArea.bottom + 5, chartArea.width, 15);
          ctx.restore();
        },
      };

      const customAxis = {
        id: 'customAxis',
        beforeDraw: (chart) => {
          drawChartLabels(chart, xmin.value, xmax.value, 'Normalised Specific Ventilation');
        },
      };

      const ctx = ventDistChart.value.getContext('2d');
      if (props.showInformationModal) {
        getScan.value.product === 'DAW' ? (ctx.canvas.height = 115) : (ctx.canvas.height = 120);
      }
      if (!ctx) {
        return;
      }

      const chartData = {
        labels: xData,
        datasets: [
          {
            label: legend,
            data: yData,
            backgroundColor: 'rgba(32, 32, 32, .5)',
            borderColor: 'rgb(32, 32, 32)',
          },
        ],
      };

      const chartAreaBorder = {
        type: 'box',
        xScaleID: 'xAxes',
        yScaleID: 'yAxes',
        backgroundColor: 'transparent',
        borderColor: 'rgb(105,105,105)',
        borderWidth: 2,
      };

      const annotationBox = {
        type: 'box',
        xScaleID: 'xAxes',
        yScaleID: 'yAxes',
        xMax: '0.60',
        backgroundColor: 'rgba(253, 124, 110, .25)',
        borderColor: 'transparent',
        borderWidth: 1,
        drawTime: 'beforeDatasetsDraw',
      };

      const annotationText = {
        type: 'label',
        xScaleID: 'xAxes',
        yScaleID: 'yAxes',
        xMin: xData[0],
        xMax: (xData.length * props.VDP) / 100,
        yMin: Math.max(...yData) - 1,
        yMax: Math.max(...yData) - 0.5,
        borderColor: 'transparent',
        label: {
          enabled: true,
          content: ['VDP ' + props.VDP + '%'],
          backgroundColor: 'transparent',
        },
      };

      const histogramChart = new Chart(ctx, {
        type: 'bar',
        data: chartData,
        options: {
          plugins: {
            autocolors: false,
            legend: { display: false },
            annotation: {
              annotations: [
                chartAreaBorder,
                annotationBox,
                annotationText,
                {
                  type: 'label',
                  xMin: xData[0],
                  xMax: (xData.length * props.VDP) / 100,
                  yMin: Math.max(...yData) - 1,
                  yMax: Math.max(...yData) - 0.5,
                  backgroundColor: 'transparent',
                  font: {
                    size: 10,
                  },
                },
              ],
            },
            gradientBackground: true,
            customAxis: true,
          },
          scales: {
            xAxes: {
              display: false,
            },
            x: {
              display: true,
              type: 'linear',
              min: xmin.value,
              max: xmax.value,
              ticks: {
                display: false,
                stepSize: 0.5,
                padding: 15,
              },
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Percentage',
              },
            },
            yAxes: {
              display: false,
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
          },
          events: [],
          layout: {
            padding: {
              bottom: 50,
            },
          },
        },
        plugins: [gradientPlugin, customAxis],
      });
    });

    return { ventDistChart, Information, vdhLabel, histogramDescLabel, getReportGuideImages, xmin, xmax };
  },
});
</script>
