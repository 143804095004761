import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-63f6f675"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid grid-cols-12 gap-1" }
const _hoisted_2 = { class: "col-span-9" }
const _hoisted_3 = {
  key: 0,
  class: "axial col-span-3"
}
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { class: "h-[31%] mb-1" }
const _hoisted_6 = { class: "h-[31%] mb-1" }
const _hoisted_7 = { class: "h-[31%]" }
const _hoisted_8 = { class: "grid grid-cols-12 mt-1" }
const _hoisted_9 = { class: "col-span-7 col-start-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSubHeader = _resolveComponent("BaseSubHeader")!
  const _component_Vent4DZoomImg = _resolveComponent("Vent4DZoomImg")!
  const _component_BaseImg = _resolveComponent("BaseImg")!
  const _component_ColorBar = _resolveComponent("ColorBar")!
  const _component_BaseSubCard = _resolveComponent("BaseSubCard")!

  return (_openBlock(), _createBlock(_component_BaseSubCard, { "data-cy": "Main4DView" }, {
    default: _withCtx(() => [
      _createVNode(_component_BaseSubHeader, {
        title: _ctx.rvLabel,
        showInformationModal: _ctx.showInformationModal,
        showTitle: _ctx.showTitle,
        informationModalText: _ctx.Information.REGIONAL_VENTILATION_VISUALIZATION
      }, null, 8, ["title", "showInformationModal", "showTitle", "informationModalText"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Vent4DZoomImg, {
            imageUrl: _ctx.coronalImageUrl,
            showGuides: true,
            guides: _ctx.axialGuides
          }, null, 8, ["imageUrl", "guides"])
        ]),
        (_ctx.axialNamedImagesUrl)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('positions.upper')), 1),
                  _createVNode(_component_BaseImg, {
                    imageUrl: _ctx.axialNamedImagesUrl[0],
                    spinnerLoading: true
                  }, null, 8, ["imageUrl"])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('positions.middle')), 1),
                  _createVNode(_component_BaseImg, {
                    imageUrl: _ctx.axialNamedImagesUrl[1],
                    spinnerLoading: true
                  }, null, 8, ["imageUrl"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('positions.lower')), 1),
                  _createVNode(_component_BaseImg, {
                    imageUrl: _ctx.axialNamedImagesUrl[2],
                    spinnerLoading: true
                  }, null, 8, ["imageUrl"])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_ColorBar, {
            xmin: 0,
            xmax: 2
          })
        ])
      ])
    ]),
    _: 1
  }))
}