
import { computed, onMounted, defineComponent, ref } from 'vue';

import { useScanStore } from '@/stores/scan';
import { useSliceStore } from '@/stores/slice';
import { useRouter, useRoute } from 'vue-router';

import axios from 'axios';
import { setItemLS, getItemLS, removeItemLS } from '@/utility/localStorage';
import dayjs from 'dayjs';
import { useInformationStore } from './stores/information';
import { useLabelsStore } from '@/stores/labels';

export default defineComponent({
  setup() {
    const done = ref(false);
    const scanStore = useScanStore();
    const getScan = computed(() => scanStore.getScan);
    const sliceStore = useSliceStore();
    const router = useRouter();
    const route = useRoute();
    const informationStore = useInformationStore();
    const labelsStore = useLabelsStore();

    const checkLocalStorage = () => {
      const access = getItemLS('access');

      if (access) {
        if (dayjs(access.expiry).isBefore(dayjs())) {
          removeItemLS('access');
          return null;
        }

        return access;
      } else {
        return null;
      }
    };

    onMounted(async () => {
      let scanId, secret;
      let usedStorage = false;
      const pathArray = window.location.pathname.split('/');
      pathArray.shift();

      if (pathArray.length < 2) {
        const access = checkLocalStorage();
        if (!access) {
          router.push('/error/401');
        } else {
          scanId = access.resultId;
          secret = access.secret;
          usedStorage = true;
        }
      } else {
        scanId = pathArray[1];
        secret = pathArray[2];
      }
      if (scanId && secret) {
        axios.defaults.headers.common['x-api-secret'] = secret;

        const scanFetchResult = await scanStore.fetchScan(scanId);
        const resp = await labelsStore.fetchLabels(scanId);

        if (scanFetchResult.success) {
          if (scanFetchResult.data?.data.data.result.status == 'FAIL') {
            if (!(pathArray[3] && pathArray[3] === 'preview-report')) {
              router.push('/error/404');
            }
          } else if (getScan.value!.issueNumber < 1) {
            router.push('/error/401');
          } else {
            const sliceFetchResult = await sliceStore.fetchSlices(scanId);

            if (sliceFetchResult.success) {
              if (!(pathArray[3] && pathArray[3] === 'preview-report')) {
                if (!usedStorage || !route.matched.length) {
                  router.push('/result-summary');
                }

                setItemLS('access', {
                  resultId: scanId,
                  secret,
                  expiry: dayjs().add(15, 'minute'),
                });
              }
            } else {
              router.push('/error/' + sliceFetchResult.errorMsg);
              removeItemLS('access');
            }
          }
        } else {
          router.push('/error/' + scanFetchResult.errorMsg);
          removeItemLS('access');
        }
      } else {
        router.push('/error/401');
      }

      done.value = true;
    });

    return { done };
  },
});
