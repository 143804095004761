
import { ref, computed, defineComponent } from 'vue';

const DEFAULT_IMAGE_HEIGHT = 600;

export default defineComponent({
  props: {
    imageUrl: {
      type: String,
      default: null,
    },
    alt: String,
    showGuides: {
      type: Boolean,
      default: false,
    },
    guides: {
      type: Object,
      required: false,
    },
    previewReport: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const guidePositions = ref({
      upper: 0,
      centre: 0,
      lower: 0,
    });

    const setTopPostion = (val: number) => `top: ${val}%`;
    const getUpperTop = computed(() => setTopPostion(guidePositions.value.upper));
    const getCentreTop = computed(() => setTopPostion(guidePositions.value.centre));
    const getLowerTop = computed(() => setTopPostion(guidePositions.value.lower));

    const getTopPercent = (position: number, imageHeight: number) => {
      if (!position) {
        return 0;
      }

      const heightRatio = position / imageHeight;
      return heightRatio * 100;
    };

    const imageHeightUpdate = (height: number) => {
      if (!props.showGuides) {
        return;
      }

      const imageHeight = height || DEFAULT_IMAGE_HEIGHT;
      guidePositions.value.upper = props.guides ? getTopPercent(props.guides.upper, imageHeight) : 0;
      guidePositions.value.centre = props.guides ? getTopPercent(props.guides.centre, imageHeight) : 0;
      guidePositions.value.lower = props.guides ? getTopPercent(props.guides.lower, imageHeight) : 0;
    };

    return {
      guidePositions,
      getUpperTop,
      getCentreTop,
      getLowerTop,
      getTopPercent,
      imageHeightUpdate,
    };
  },
});
