import { defineStore } from 'pinia';
import axios from 'axios';

import { Slice, File } from '@/types/Slice';

import { Views } from '@/constants/Views';
import { AxialSlices, CoronalSlices } from '@/constants/Slices';
import { getEndpoint } from '@/apiConnector/endpoints';

import { handleEndpointErrors, handleEndpointSuccess } from '@/utility/helpers';
interface State {
  slices: Slice[];
}

export const useSliceStore = defineStore('slice', {
  state: (): State => ({
    slices: [],
  }),
  getters: {
    getSlices: (state) => state.slices,
    getSlicesByType: (state) => (plane: Views) => state.slices.find((slice) => slice.plane === plane),
    filterSliceImages:
      (state) =>
      (plane: Views, namedOnly: boolean, position: AxialSlices | CoronalSlices | null = null) => {
        const slices = state.slices.find((slice) => slice.plane === plane);
        if (!slices) {
          return [];
        }

        const slicesImages = slices.images
          .filter((img) => {
            if (position && img.position !== position) {
              return false;
            }
            return true;
          })
          .filter((img) => {
            if (namedOnly) {
              return img.named;
            }
            return true;
          });

        return slicesImages;
      },
  },
  actions: {
    async fetchSlices(scanId: string) {
      try {
        const url = getEndpoint('slice', scanId);
        const resp = await axios.get(url);
        const data = resp.data.data.sliceInfo as Slice[];

        this.slices = data;

        return handleEndpointSuccess(data);
      } catch (e: unknown) {
        return handleEndpointErrors(e);
      }
    },
    async renderImages(resultId: any, location: any) {
      try {
        let imgUrl = '';

        const url = getEndpoint('file', resultId);
        const res = await axios.get(url + '?name=' + location, { responseType: 'blob' });
        const blob = new Blob([res.data]);
        imgUrl = URL.createObjectURL(blob);
        return handleEndpointSuccess(imgUrl);
      } catch (e: unknown) {
        return handleEndpointErrors(e, true);
      }
    },
  },
});
