
import { ReportTypes } from '@/../src/constants/ReportTypes';
import { computed, defineComponent } from 'vue';
import ReportSummary from './ReportSummary.vue';
import AxialImages from './AxialImages.vue';
import CoronalImages from './CoronalImages.vue';
import Contact4DMedical from './Contact4DMedical.vue';
import HowToReadReport from './HowToReadReport.vue';
import VentilationVideo from './VentilationVideo.vue';
import EmptyReport from './EmptyReport.vue';
import AdvisorySection from '../components/AdvisorySection.vue';
import { useScanStore } from '@/stores/scan';
import FlowGraph from './FlowGraph.vue';
import SpecificVentilation from './SpecificVentilation.vue';
import { ExclamationCircleIcon, ExclamationIcon } from '@heroicons/vue/solid';

export default defineComponent({
  components: {
    ReportSummary,
    AxialImages,
    CoronalImages,
    Contact4DMedical,
    HowToReadReport,
    VentilationVideo,
    EmptyReport,
    FlowGraph,
    SpecificVentilation,
    AdvisorySection,
  },
  setup() {
    const scanStore = useScanStore();
    const getScan = computed(() => (scanStore.getScan ? scanStore.getScan : {}));

    return { getScan, ReportTypes };
  },
});
