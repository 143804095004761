
import { PhoneIcon, AtSymbolIcon, MailIcon, GlobeAltIcon } from '@heroicons/vue/solid';
import { computed } from 'vue';

import { useContactStore } from '@/stores/contact';

export default {
  components: {
    PhoneIcon,
    AtSymbolIcon,
    MailIcon,
    GlobeAltIcon,
  },
  setup() {
    const contactStore = useContactStore();
    const contact = computed(() => contactStore.getContact);

    return { contact };
  },
};
