import { App } from 'vue';
import BaseHeader from '@/components/ui/BaseHeader.vue';
import BaseImg from '@/components/ui/BaseImg.vue';
import BaseDropDown from '@/components/ui/BaseDropDown.vue';
import BaseCard from '@/components/ui/BaseCard.vue';
import BaseSubHeader from '@/components/ui/BaseSubHeader.vue';
import BaseSubCard from '@/components/ui/BaseSubCard.vue';
import BaseSubCardContact from '@/components/ui/BaseSubCardContact.vue';

import BaseSlider from '@/components/ui/BaseSlider.vue';
import BaseIconButton from '@/components/ui/BaseIconButton.vue';
import 'gitart-vue-dialog/dist/style.css';
import { GDialog } from 'gitart-vue-dialog';

export default {
  install(app: App) {
    app.component('BaseHeader', BaseHeader);
    app.component('BaseImg', BaseImg);
    app.component('BaseDropDown', BaseDropDown);
    app.component('BaseCard', BaseCard);
    app.component('BaseSubHeader', BaseSubHeader);
    app.component('BaseSubCard', BaseSubCard);
    app.component('BaseSubCardContact', BaseSubCardContact);
    app.component('GDialog', GDialog);
    app.component('BaseSlider', BaseSlider);
    app.component('BaseIconButton', BaseIconButton);
  },
};
