
import { defineComponent, onMounted, ref } from 'vue';
import { MetricsDetailService } from '@/service/MetricsDetailService';
import { ReportSummaryMetricsDetailService } from '@/service/ReportSummaryMetricsDetailService';
import { SpecificVentilationMetricsDetailService } from '@/service/SpecificVentilationMetricsDetailService';
import { FlowOscillatoryMetricsDetailsService } from '@/service/FlowOscillatoryMetricsDetailsService';
import { TabTypes } from '@/constants/TabTypes';
import { MetricsDetails } from '@/types/Metrics';

export default defineComponent({
  components: {},
  props: {
    metricsType: {
      type: String,
      required: false,
    },
    showInformationModal: {
      type: Boolean,
      required: false,
    },
  },

  setup(props) {
    let gridClass = ref('');
    let metricsDataList = ref<MetricsDetails[]>([]);
    onMounted(async () => {
      let metricDetailService: MetricsDetailService = getMetricsServiceInstance();
      metricDetailService.getMetricsData();
      gridClass.value = metricDetailService.gridClass;
      metricsDataList.value = metricDetailService.metricsDataList;
    });

    const getMetricsServiceInstance = () => {
      if (props.metricsType === TabTypes.REPORT_SUMMARY) return new ReportSummaryMetricsDetailService();
      if (props.metricsType === TabTypes.FLOW_OSCILLATORY) return new FlowOscillatoryMetricsDetailsService();
      if (props.metricsType === TabTypes.SPECIFIC_VENTILATION) return new SpecificVentilationMetricsDetailService();
      throw new Error(`Unsupported tab type ${props.metricsType}`);
    };
    return { gridClass, metricsDataList };
  },
});
