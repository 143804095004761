import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6f7da90c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseImg = _resolveComponent("BaseImg")!
  const _component_SidebarMenu = _resolveComponent("SidebarMenu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SidebarMenu, {
      menu: _ctx.menu,
      class: "navbar",
      "data-cy": _ctx.menu
    }, {
      "dropdown-icon": _withCtx(({ isOpen }) => [
        (!isOpen)
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
              _createVNode(_component_BaseImg, { imageUrl: _ctx.downIcon }, null, 8, ["imageUrl"])
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createVNode(_component_BaseImg, { imageUrl: _ctx.upIcon }, null, 8, ["imageUrl"])
            ]))
      ]),
      _: 1
    }, 8, ["menu", "data-cy"])
  ]))
}