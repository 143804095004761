import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f6a13524"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full relative" }
const _hoisted_2 = ["min", "max"]
const _hoisted_3 = {
  ref: "valueBubble",
  class: "bubble"
}
const _hoisted_4 = { class: "bubble-child" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      ref: "slider",
      type: "range",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
      min: _ctx.min,
      max: _ctx.max,
      class: "slider",
      "data-cy": "slider"
    }, null, 8, _hoisted_2), [
      [_vModelText, _ctx.localValue]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.localValue), 1)
    ], 512)
  ]))
}