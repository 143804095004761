
import { ref, computed, defineComponent, watch, onMounted } from 'vue';
import { marked } from 'marked';
import { useInformationStore } from '@/stores/information';
import { useScanStore } from '@/stores/scan';
import { useNotification } from '@/composables/notification';

export default defineComponent({
  props: {
    title: { default: '', type: String },
    markdownTitle: {
      required: true,
      default: '',
      type: String,
    },
    imageGuide: {
      default: '',
      type: String,
    },
    preview: {
      default: false,
      type: Boolean,
    },
    onSubHeader: {
      default: false,
      type: Boolean,
    },
    dialogState: {
      default: false,
      type: Boolean,
    },
  },
  setup(props) {
    const informationStore = useInformationStore();
    const scanStore = useScanStore();
    const notification = useNotification();

    const getScan = computed(() => scanStore.getScan);
    const content = computed(() => {
      return informationStore.getInformationTooltip;
    });

    const retrieveContent = () => {
      let finalContent = content.value.find((x: { key: string }) => x.key === props.markdownTitle)?.data;
      if (finalContent) {
        finalContent = finalContent.substr(finalContent.indexOf('\n'));
      }
      return finalContent!;
    };

    const handleInformationTooltipRequest = async () => {
      const resp = await informationStore.getInformationTooltipRequest(props.markdownTitle, getScan.value?.resultId);
      if (!resp.success) {
        notification.showError(resp.errorMsg + '');
        return;
      }
    };

    let updatedContent = ref('');
    let firstLoading = true;

    onMounted(async () => {
      if (props.preview) {
        await handleInformationTooltipRequest();
        updatedContent.value = retrieveContent();
      }
    });

    watch(
      () => props.dialogState,
      async () => {
        if (props.dialogState) {
          // Image loading
          Object.values(informationStore.reportGuideImages).filter((val) => val != '').length > 1 // Initial load is only for product logo
            ? (firstLoading = false)
            : (firstLoading = true);

          if (getScan.value && firstLoading) {
            const resp = await informationStore.getReportGuideImagesRequest(
              getScan.value?.resultId,
              getScan.value!.product,
            );
            firstLoading = false;
          }
          // content loading
          if (content.value.filter((val) => val.key === props.markdownTitle).length == 0) {
            /* vendors contains the element we're looking for */
            if (props.onSubHeader || props.markdownTitle) {
              await handleInformationTooltipRequest();
            }
          }
          updatedContent.value = retrieveContent();
        }
      },
      { immediate: true },
    );

    return { content, marked, updatedContent };
  },
});

import interact from 'interactjs';
interact('.g-dialog-content').draggable({
  // enable inertial throwing
  inertia: true,
  // keep the element within the area of it's parent
  modifiers: [
    interact.modifiers.restrictRect({
      restriction: 'parent',
      endOnly: true,
    }),
  ],
  // enable autoScroll
  autoScroll: true,

  listeners: {
    // call this function on every dragmove event
    move: dragMoveListener,
  },
});

function dragMoveListener(event: any) {
  var target = event.target;
  // keep the dragged position in the data-x/data-y attributes
  var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
  var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

  // translate the element
  target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

  // update the posiion attributes
  target.setAttribute('data-x', x);
  target.setAttribute('data-y', y);
}

// this function is used later in the resizing and gesture demos
(window as any).dragMoveListener = dragMoveListener;
