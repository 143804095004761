import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home print-mode" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { "data-cy": "report-summary" }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdvisorySection = _resolveComponent("AdvisorySection")!
  const _component_ReportSummary = _resolveComponent("ReportSummary")!
  const _component_AxialImages = _resolveComponent("AxialImages")!
  const _component_CoronalImages = _resolveComponent("CoronalImages")!
  const _component_VentilationVideo = _resolveComponent("VentilationVideo")!
  const _component_SpecificVentilation = _resolveComponent("SpecificVentilation")!
  const _component_FlowGraph = _resolveComponent("FlowGraph")!
  const _component_HowToReadReport = _resolveComponent("HowToReadReport")!
  const _component_EmptyReport = _resolveComponent("EmptyReport")!
  const _component_Contact4DMedical = _resolveComponent("Contact4DMedical")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.getScan.result.advisories)
      ? (_openBlock(), _createBlock(_component_AdvisorySection, {
          key: 0,
          advisories: _ctx.getScan.result.advisories
        }, null, 8, ["advisories"]))
      : _createCommentVNode("", true),
    (_ctx.getScan.result.status === `OK`)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_ReportSummary, {
              showInformationModal: false,
              previewReport: true
            })
          ]),
          _createVNode(_component_AxialImages, {
            "data-cy": "axial-images",
            class: "break-before-page",
            showInformationModal: false,
            showSlicedZoom: false
          }),
          _createVNode(_component_CoronalImages, {
            "data-cy": "coronal-images",
            class: "break-before-page",
            showInformationModal: false,
            showSlicedZoom: false
          }),
          (_ctx.getScan.product === _ctx.ReportTypes.DAW)
            ? (_openBlock(), _createBlock(_component_VentilationVideo, {
                key: 0,
                class: "break-before-page max-[596px]:height-2/5",
                "data-cy": "ventilation-video-report",
                previewReport: true,
                showInformationModal: false
              }))
            : _createCommentVNode("", true),
          (_ctx.getScan.specificVentilationHistogram)
            ? (_openBlock(), _createBlock(_component_SpecificVentilation, {
                key: 1,
                "data-cy": "specific-ventilation",
                class: "break-before-page max-[596px]:height-2/5",
                showInformationModal: false
              }))
            : _createCommentVNode("", true),
          (_ctx.getScan.flowOscillatoryMetrics)
            ? (_openBlock(), _createBlock(_component_FlowGraph, {
                key: 2,
                "data-cy": "flow-graph",
                showInformationModal: false,
                class: "break-before-page"
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_HowToReadReport, {
            "data-cy": "how-to-read-report",
            showInformationModal: false,
            reportType: _ctx.getScan.product,
            class: "break-before-page"
          }, null, 8, ["reportType"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_EmptyReport)
        ])),
    _createVNode(_component_Contact4DMedical, {
      "data-cy": "contact4d-medical",
      class: "break-before-page",
      previewReport: true
    })
  ]))
}