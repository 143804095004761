
import { defineComponent, PropType } from 'vue';
import { ExclamationIcon } from '@heroicons/vue/outline';
import warningIconPath from '@/assets/images/iso-warning.png';

export default defineComponent({
  components: {
    ExclamationIcon,
  },
  props: {
    advisories: {
      type: Array as PropType<Array<{ advisoryCode: string; message: string; level: string; endUserMessage: string }>>,
      required: true,
    },
  },
  methods: {
    messageClasses(level: string) {
      return {
        'flex flex-row': true,
        'bg-white': level === 'NOTICE',
        'bg-yellow-light': level === 'CAUTION',
        'bg-red': level === 'WARNING',
      };
    },
    iconForLevel(level: string) {
      switch (level) {
        case 'CAUTION':
          return ExclamationIcon;
        case 'WARNING':
          return warningIconPath;
        default:
          return null;
      }
    },
  },
});
