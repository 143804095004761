import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "text-caption 3xl:text-body-2 4xl:text-heading-4" }
const _hoisted_2 = {
  key: 0,
  class: "m-0"
}
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "flex justify-between w-full" }
const _hoisted_5 = { class: "flex justify-between w-full" }
const _hoisted_6 = { class: "font-bold text-body-2 3xl:text-heading-6 4xl:text-heading-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.colorBar)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "w-full",
            src: _ctx.colorbarImg,
            alt: _ctx.$t('colorbar.alt')
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredValues, (value, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, _toDisplayString(value), 1))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('colorbar.less')), 1),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('colorbar.legend')), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('colorbar.more')), 1)
    ])
  ]))
}