
import { ref, defineComponent } from 'vue';
import InformationIcon from '@/assets/images/icons/information.svg';
import InformationModal from '@/components/InformationModal.vue';
export default defineComponent({
  components: {
    InformationModal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
    markdownTitle: {
      default: '',
      type: String,
    },
  },
  setup() {
    const informationIcon = InformationIcon;
    const dialogState = ref(false);

    const changeDialogState = () => {
      dialogState.value = true;
    };
    return { informationIcon, dialogState, changeDialogState };
  },
});
