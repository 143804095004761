import { MetricsDetailService } from './MetricsDetailService';
import { Information } from '@/constants/Information';
import { useLabelsStore } from '@/stores/labels';
import { useScanStore } from '@/stores/scan';
import { useI18n } from 'vue-i18n';
import { MetricsDetails } from '@/types/Metrics';
export class SpecificVentilationMetricsDetailService implements MetricsDetailService {
  gridClass = '';
  metricsDataList: MetricsDetails[] = [];
  labelsStore = useLabelsStore();
  scanStore = useScanStore();
  translate = useI18n();
  getMetricsData() {
    this.metricsDataList.push(this.getLowVentilationRegion());
    this.metricsDataList.push(this.getMeanSpecificVentilation());
    this.gridClass = 'sm:grid-cols-2 lg:grid-cols-1 print:grid-cols-1';
  }

  getLowVentilationRegion(): MetricsDetails {
    return {
      headerTitle: this.labelsStore?.getLabels?.specificVentilation?.lvr || '',
      informationModalText: Information.SPECIFIC_VENTILATION_LVR,
      barDetails: [
        {
          value: this.scanStore?.getScan?.specificVentilationHistogram?.lvr?.toFixed(1) || '',
          class: 'w-full',
          symbol: '%',
          valueClass: 'bg-orange border-teal',
        },
      ],
    };
  }

  getMeanSpecificVentilation(): MetricsDetails {
    return {
      headerTitle: this.labelsStore?.getLabels?.specificVentilation?.msv || '',
      informationModalText: Information.SPECIFIC_VENTILATION_MSV,
      barDetails: [
        {
          value: (
            Math.round((this.scanStore?.getScan?.specificVentilationHistogram?.averageVentilation || 0) * 100) / 100
          ).toFixed(2),
          class: 'w-full',
          valueClass: 'border-black border-dashed',
        },
      ],
    };
  }
}
