import { defineStore } from 'pinia';
import axios from 'axios';
import { Contact } from '@/types/Contact';

import { handleEndpointErrors, handleEndpointSuccess } from '@/utility/helpers';
import { getEndpoint } from '@/apiConnector/endpoints';

interface State {
  contact: Contact | null;
  disclaimer: { [key: string]: any }; // Add index signature
}

export const useContactStore = defineStore('contact', {
  state: (): State => ({
    contact: null,
    disclaimer: {},
  }),

  getters: {
    getContact: (state) => state.contact,
    getDisclaimer: (state) => (key: string) => state.disclaimer[key],
  },
  actions: {
    async fetchContact(scanId: string) {
      try {
        const url = getEndpoint('content', scanId);
        const resp = await axios.get(url + '/contact.json');
        const data = resp.data as Contact;
        this.contact = data;

        return handleEndpointSuccess(data);
      } catch (e: unknown) {
        return handleEndpointErrors(e);
      }
    },
    async fetchDisclaimer(key: string, scanId: string) {
      try {
        const url = getEndpoint('content', scanId);
        const resp = await axios.get(`${url}/disclaimer/${key}`);
        const data = resp.data;

        this.disclaimer[key] = data;
        return handleEndpointSuccess(data);
      } catch (e: unknown) {
        return handleEndpointErrors(e);
      }
    },
  },
});
